import { toPremium, toCoveredAmount } from '../common/numberFormats';
import { push } from 'connected-react-router';
import { ROUTES } from '../../config/constants';
import { calculatePricingFromState } from '../customization/calculatePricing';
import * as R from 'ramda';

const getId = title => {
  const ids = {
    Life: 'life',
    Disability: 'disability',
    'Family Funeral': 'familyFuneral',
    'Additional Lives on Funeral Benefit': 'extendedFamilyFuneral',
  };
  return ids[title];
};

const formatProductSelection = R.pipe(
  R.values,
  R.filter(p => p.selected),
  R.map(
    R.applySpec({
      id: p => getId(p.title),
      name: p => p.title,
      price: p => toCoveredAmount(p.insuredAmount),
    })
  )
);

const generateSettingsFromProps = props => {
  const { packageUrlSlug } = props;
  return [
    {
      left: {
        title: 'Adjust cover',
        className: 'setting',
        type: 'button',
        onClick: () => {
          props.dispatch(push(`${ROUTES.CUSTOMIZE}${packageUrlSlug}`));
        },
        color: 'primary',
      },
      right: {
        title: 'Get Help?',
        className: 'setting',
        type: 'button',
        onClick: () => props.openCallmeBackMenu(),
        color: 'primary',
        position: 'end',
      },
    },
  ];
};

const exists = item => !R.isNil(item);

const makeMutable = item => item.toJS();

const generatePackageSpecificRoutes = (startsWith, state) => {
  const packages = R.keys(state.get('packages').toJS());
  return packages.map(p => `${startsWith}/${p}`);
};

const getBlacklistedRoutes = state => {
  const customizationPageRoutes = R.union(
    generatePackageSpecificRoutes('/customization', state),
    generatePackageSpecificRoutes('/nearly-there', state)
  );
  return R.union(['/'], customizationPageRoutes);
};

const hasPricing = productSelection =>
  R.pipe(R.values, R.head, R.has('insuredAmount'))(productSelection.toJS());

const hasRopPricing = reccomendation =>
  R.has('productRopPriceMap', reccomendation.toJS());

const allRequiredDataIsPresent = (props, state) =>
  !exists(props.productSelection)
    ? false
    : hasPricing(props.productSelection) &&
      hasRopPricing(state.get('recommendation'));

export const getPricingOverlayConfig = (props, state) => {
  const settings = generateSettingsFromProps(props);
  const pricing = allRequiredDataIsPresent(props, state)
    ? calculatePricingFromState(state, props.packageUrlSlug)
    : {};
  const productSelection = exists(props.productSelection)
    ? makeMutable(props.productSelection)
    : {};
  const currentPage = R.isEmpty(props.currentPage) ? '/' : props.currentPage;
  const hasPricingAndProductSelection =
    !R.isEmpty(pricing) && !R.isEmpty(productSelection);
  const products = !R.isEmpty(productSelection)
    ? formatProductSelection(productSelection)
    : productSelection;
  const withRop = state.getIn(['productSelection', 'withRop']);
  const productsWithRop = withRop
    ? R.append(
        { id: 'Cashback', name: 'Cashback', price: `${pricing.ropPrecentage}%` },
        products
      )
    : products;
  return hasPricingAndProductSelection
    ? {
        currentPage,
        blacklistedRoutes: getBlacklistedRoutes(state),
        total: toPremium(pricing.finalCost),
        products: productsWithRop,
        settings,
      }
    : null;
};
