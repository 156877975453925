// Only log in browser
const isRaygunDefined = () => typeof rg4js !== 'undefined';

export default initialState => {
  if (initialState.config.IS_DEV === false && isRaygunDefined()) {
    rg4js('apiKey', initialState.config.RAYGUN_API_KEY);
    rg4js('withTags', [
      initialState.config.APP_ID,
      'app',
      initialState.config.VERSION,
    ]);
    rg4js('enableCrashReporting', true);
    rg4js('setVersion', initialState.config.VERSION);
  }
};

export const reportError = (error, store, action) => {
  const state = store.getState().toJS();
  const isDev = state.config.IS_DEV;
  if (isDev === false && isRaygunDefined()) {
    const errorState = {
      error,
      customData: {
        action: action.type,
        interactionId: state.interactionId,
        routing: state.routing,
        selectedPackageSlug: state.selectedPackageSlug,
      },
    };
    rg4js('send', errorState);
  }
};

export const catchErrorMiddleware = store => next => action => {
  try {
    return next(action);
  } catch (error) {
    reportError(error, store, action);
    return error;
  }
};
