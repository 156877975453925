"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBankingContractInceptionDate = exports.extractBankingContractDetails = exports["default"] = exports.validateBankingDetails = exports.validateInceptionMonth = exports.validateAccountType = exports.validateBranchCode = exports.validateAccountNumber = exports.validateDebitDay = exports.getAccountValidatorForBranch = exports.getBankFromBranchCode = exports.UNIVERSAL_BRANCH_CODES = exports.BANK_ACCOUNT_TYPES = void 0;

var _immutable = require("immutable");

var _validators = require("../validators");

var _moment = _interopRequireDefault(require("moment"));

var R = _interopRequireWildcard(require("ramda"));

var _accountNumberValidators = _interopRequireDefault(require("./accountNumberValidators"));

var _constants = require("../constants");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var BANK_ACCOUNT_TYPES = ['Cheque', 'Savings', 'Transmission'];
exports.BANK_ACCOUNT_TYPES = BANK_ACCOUNT_TYPES;
var UNIVERSAL_BRANCH_CODES = (0, _immutable.fromJS)({
  ABSA: {
    code: '632005',
    validator: _accountNumberValidators["default"].validateAbsa
  },
  'African Bank': {
    code: '430000',
    validator: _accountNumberValidators["default"].validateAfricanBank
  },
  'Capitec Bank': {
    code: '470010',
    validator: _accountNumberValidators["default"].validateCapitec
  },
  'Discovery Bank': {
    code: '679000',
    validator: _accountNumberValidators["default"].validateDiscoveryBank
  },
  'FNB/RMB': {
    code: '250655',
    validator: _accountNumberValidators["default"].validateFNB
  },
  'Standard Bank': {
    code: '051001',
    validator: _accountNumberValidators["default"].validateStandardBank
  },
  Investec: {
    code: '580105',
    validator: _accountNumberValidators["default"].validateInvestec
  },
  Nedbank: {
    code: '198765',
    validator: _accountNumberValidators["default"].validateNedbank
  },
  'Bank of Athens': {
    code: '410506',
    validator: _accountNumberValidators["default"].validateBankofAthens
  },
  'Bidvest Bank': {
    code: '462005',
    validator: _accountNumberValidators["default"].validateBidvest
  },
  'SA Post Bank': {
    code: '460005',
    validator: _accountNumberValidators["default"].validateSAPost
  },
  'Tyme Bank': {
    code: '678910',
    validator: _accountNumberValidators["default"].validateTyme
  },
  'Bank Zero': {
    code: '888000',
    validator: _accountNumberValidators["default"].validateBankZero
  },
  'Finbond': {
    code: '589000',
    validator: _accountNumberValidators["default"].validateFinbond
  },
  'Sasfin Bank': {
    code: '683000',
    validator: _accountNumberValidators["default"].validateSasfinBank
  },
  'Ithala Bank': {
    code: '754126',
    validator: _accountNumberValidators["default"].validateOther
  },
  'Capitec Business': {
    code: '450105',
    validator: _accountNumberValidators["default"].validateOther
  },
  Other: {
    code: '',
    validator: _accountNumberValidators["default"].validateOther
  }
});
exports.UNIVERSAL_BRANCH_CODES = UNIVERSAL_BRANCH_CODES;

var getBankFromBranchCode = function getBankFromBranchCode(code) {
  return R.pipe(R.find(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        details = _ref2[1];

    return details.code === code;
  }), function (val) {
    return val ? val[0] : null;
  })(R.toPairs(UNIVERSAL_BRANCH_CODES.toJS()));
};

exports.getBankFromBranchCode = getBankFromBranchCode;

var getAccountValidatorForBranch = function getAccountValidatorForBranch(branchCode) {
  var matchingValidators = UNIVERSAL_BRANCH_CODES.filter(function (b) {
    return b.get('code') === branchCode;
  }).map(function (b) {
    return b.get('validator');
  });

  if (matchingValidators.size) {
    return matchingValidators.first();
  }

  return _accountNumberValidators["default"].validateOther;
};

exports.getAccountValidatorForBranch = getAccountValidatorForBranch;

var validateDebitDay = function validateDebitDay(value) {
  var validation = (0, _validators.validateRequiredNumberText)(value);
  var day = parseInt(value, 10);

  if (Number.isNaN(day) || day < 1 || day > 31) {
    validation.isValid = false;
    validation.errors.push('Debit day needs to be between 1 and 31');
  }

  return validation;
};

exports.validateDebitDay = validateDebitDay;

var lessThan15Numbers = function lessThan15Numbers(value) {
  return value && value.length && value.length <= 15;
};

var validateAccountNumber = function validateAccountNumber(validator) {
  return function (value) {
    var validation = (0, _validators.validateRequiredNumberText)(value);

    if (!lessThan15Numbers(value)) {
      validation.isValid = false;
      validation.errors.push('Account number too long');
    }

    if (!validator) {
      return validation;
    }

    if (!validator(value) && value) {
      validation.isValid = false;
      validation.errors.push('Account number not valid for bank');
    }

    return validation;
  };
};

exports.validateAccountNumber = validateAccountNumber;

var is6Numbers = function is6Numbers(value) {
  return value && value.length && value.length === 6;
};

var validateBranchCode = function validateBranchCode(value) {
  var validation = (0, _validators.validateRequiredNumberText)(value);

  if (!is6Numbers(value)) {
    validation.isValid = false;
    validation.errors.push('Branch code should have a length of 6');
  }

  return validation;
};

exports.validateBranchCode = validateBranchCode;

var isValidAccountType = function isValidAccountType(value) {
  return BANK_ACCOUNT_TYPES.indexOf(value) >= 0;
};

var validateAccountType = function validateAccountType(value) {
  var validation = (0, _validators.validateRequiredText)(value);

  if (!isValidAccountType(value)) {
    validation.isValid = false;
    validation.errors.push("Account type should be one of the following: ".concat(BANK_ACCOUNT_TYPES.join(', ')));
  }

  return validation;
};

exports.validateAccountType = validateAccountType;
var validateInceptionMonth = R.curry(function (currentDate, value) {
  var validation = (0, _validators.validateRequiredISODate)(value);

  if (validation.isValid === true) {
    var valueDate = (0, _moment["default"])(value);
    var now = (0, _moment["default"])(currentDate);
    var lastAllowableMonth = (0, _moment["default"])(currentDate).add(_constants.ALLOWED_FUTURE_DATED_MONTHS, 'months');
    var isBefore = valueDate.isBefore(now, 'month');
    var isAfter = valueDate.isAfter(lastAllowableMonth, 'month');

    if (isBefore || isAfter) {
      validation.isValid = false;
      validation.errors.push('Inception Month should be within 3 months of sale month');
    }
  }

  return validation;
});
exports.validateInceptionMonth = validateInceptionMonth;

var validateBankingDetails = function validateBankingDetails(currentDate, details) {
  return (0, _immutable.fromJS)({
    debitDay: validateDebitDay(details.get('debitDay')),
    branchCode: validateBranchCode(details.get('branchCode')),
    accountNumber: validateAccountNumber(getAccountValidatorForBranch(details.get('branchCode')))(details.get('accountNumber')),
    accountType: validateAccountType(details.get('accountType')),
    inceptionMonth: validateInceptionMonth(currentDate, details.get('inceptionMonth'))
  });
};

exports.validateBankingDetails = validateBankingDetails;
var _default = validateBankingDetails;
exports["default"] = _default;

var extractBankingContractDetails = function extractBankingContractDetails(createContractBase) {
  return {
    AccountNumber: createContractBase.getIn(['options', 'bankingDetails', 'accountNumber']),
    BranchCode: createContractBase.getIn(['options', 'bankingDetails', 'branchCode']),
    AccountType: createContractBase.getIn(['options', 'bankingDetails', 'accountType'])
  };
};

exports.extractBankingContractDetails = extractBankingContractDetails;

var getBankingContractInceptionDate = function getBankingContractInceptionDate(generationDate, selectedInceptionMonth, isUpsellSale) {
  // eslint-disable-line
  var isSameOrPriorMonth = (0, _moment["default"])(generationDate).isSameOrAfter(selectedInceptionMonth, 'month');

  if (isSameOrPriorMonth) {
    return generationDate;
  }

  return _moment["default"].utc(selectedInceptionMonth).startOf('month').toISOString();
};

exports.getBankingContractInceptionDate = getBankingContractInceptionDate;