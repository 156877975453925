export const STARTUP = 'STARTUP';
export const SET_INTERACTION_ID = 'SET_INTERACTION_ID';
export const SAVE_COMPLIANCE_REQUIREMENTS = 'SAVE_COMPLIANCE_REQUIREMENTS';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const BEGIN_LOGIN = 'BEGIN_LOGIN';

export const ANSWER_QUESTION = 'ANSWER_QUESTION';
export const ANSWER_QUESTIONS = 'ANSWER_QUESTIONS';
export const ANSWER_QUESTION_SUCCESS = 'ANSWER_QUESTION_SUCCESS';
export const ANSWER_QUESTION_ERROR = 'ANSWER_QUESTION_ERROR';
export const REMOVE_ANSWER = 'REMOVE_ANSWER';

export const VALIDATE_QUESTION_ANSWER = 'VALIDATE_QUESTION_ANSWER';
export const SET_FORM_ENTITY = 'SET_FORM_ENTITY';
export const CLEAR_FORM_ENTITY = 'CLEAR_FORM_ENTITY';

export const GET_RECOMMENDATION = 'GET_RECOMMENDATION';
export const GET_RECOMMENDATION_FROM_ANSWERS =
  'GET_RECOMMENDATION_FROM_ANSWERS';
export const SELECT_PRESELECTED_PRODUCTS = 'SELECT_PRESELECTED_PRODUCTS';
export const RECOMMENDATIONS_RECEIVED = 'RECOMMENDATIONS_RECEIVED';
export const GET_RECOMMENDATIONS_FAILED = 'GET_RECOMMENDATIONS_FAILED';
export const VIEW_QUOTE = 'VIEW_QUOTE';
export const DOWNLOAD_QUOTE = 'DOWNLOAD_QUOTE';
export const SET_SHOW_PREPARE_QUOTE_POPUP_FLAG =
  'SET_SHOW_PREPARE_QUOTE_POPUP_FLAG';
export const SET_PREPARE_QUOTE_LOADING = 'SET_PREPARE_QUOTE_LOADING';
export const UPDATE_CORRECTION_COMMENTS = 'UPDATE_CORRECTION_COMMENTS';
export const SEND_FOR_CORRECTIONS = 'SEND_FOR_CORRECTIONS';
export const SET_SEND_TO_QA_THANK_YOU_MESSAGE =
  'SET_SEND_TO_QA_THANK_YOU_MESSAGE';

export const SAVE_SELECTIONS = 'SAVE_SELECTIONS';
export const SELECT_PRODUCTS = 'SELECT_PRODUCTS';
export const UPDATE_PRODUCT_SELECTION = 'UPDATE_PRODUCT_SELECTION';
export const SET_BROKER_QA_CONFIG = 'SET_BROKER_QA_CONFIG';
export const UPDATING_PRODUCT_SELECTION = 'UPDATING_PRODUCT_SELECTION';
export const OVERWRITE_PRODUCT_SELECTION = 'OVERWRITE_PRODUCT_SELECTION';
export const SEND_SALE_TO_QA = 'SEND_SALE_TO_QA';
export const SAVE_PRODUCT_SELECTION = 'SAVE_PRODUCT_SELECTION';
export const TOGGLE_ROP = 'TOGGLE_ROP';
export const TOGGLE_EFF = 'TOGGLE_EFF';
export const SAVE_PRODUCT_SELECTION_SUCCESS = 'SAVE_PRODUCT_SELECTION_SUCCESS';
export const SAVE_PRODUCT_SELECTION_FAILURE = 'SAVE_PRODUCT_SELECTION_FAILURE';

export const SEND_QUOTE_NOTIFICATION = 'SEND_QUOTE_NOTIFICATION';
export const SEND_QUOTE_NOTIFICATION_FAILURE =
  'SEND_QUOTE_NOTIFICATION_FAILURE';
export const SEND_QUOTE_NOTIFICATION_SUCCESS =
  'SEND_QUOTE_NOTIFICATION_SUCCESS';

export const SAVE_BANKING_DETAILS = 'SAVE_BANKING_DETAILS';
export const SAVE_BANKING_DETAILS_NOT_VALID = 'SAVE_BANKING_DETAILS_NOT_VALID';
export const SAVE_BANKING_DETAILS_FAILURE = 'SAVE_BANKING_DETAILS_FAILURE';
export const SET_BANKING_DETAILS = 'SET_BANKING_DETAILS';
export const REJECT_SALE = 'REJECT_SALE';

export const SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS';
export const GET_BROKER_QA_CONFIG = 'GET_BROKER_QA_CONFIG';

export const ACCEPT_TERMS_AND_CONDITONS = 'ACCEPT_TERMS_AND_CONDITONS';
export const ACCEPT_TERMS_AND_CONDITONS_SUCCESS =
  'ACCEPT_TERMS_AND_CONDITONS_SUCCESS';
export const ACCEPT_TERMS_AND_CONDITONS_FAILURE =
  'ACCEPT_TERMS_AND_CONDITONS_FAILURE';
export const ACCEPT_TERMS_AND_CONDITONS_VALIDATION_FAILURE =
  'ACCEPT_TERMS_AND_CONDITONS_VALIDATION_FAILURE';
export const AGREE_TO_GENERAL_TERMS = 'AGREE_TO_GENERAL_TERMS';
export const AGREE_TO_POLICY_REPLACED_TERMS = 'AGREE_TO_POLICY_REPLACED_TERMS';
export const AGREE_TO_POLICY_WAIVE_PERIOD_TERMS =
  'AGREE_TO_POLICY_WAIVE_PERIOD_TERMS';
export const CHOOSE_IF_POLICY_REPLACED = 'CHOOSE_IF_POLICY_REPLACED';
export const SET_WAIVE_QUESTIONNAIRE_VALIDATION =
  'SET_WAIVE_QUESTIONNAIRE_VALIDATION';
export const SAVE_PACKAGE_ALLOWS_POLICY_REPLACEMENT_FLAG =
  'SAVE_PACKAGE_ALLOWS_POLICY_REPLACEMENT_FLAG';
export const SET_REQUEST_WAIVE_PERIOD_DETAILS_FLAG =
  'SET_REQUEST_WAIVE_PERIOD_DETAILS_FLAG';
export const CHECK_WAIVE_POLICY_PRODUCT_REQUIREMENTS =
  'CHECK_WAIVE_POLICY_PRODUCT_REQUIREMENTS';

export const ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS =
  'ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS';
export const ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_SUCCESS =
  'ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_SUCCESS';
export const ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_FAILURE =
  'ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_FAILURE';
export const ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_VALIDATION_FAILURE =
  'ACCEPT_THIRD_PARTY_TERMS_AND_CONDITONS_VALIDATION_FAILURE';
export const AGREE_TO_GENERAL_THIRD_PARTY_TERMS =
  'AGREE_TO_GENERAL_THIRD_PARTY_TERMS';
export const AGREE_TO_THIRD_PARTY_POLICY_REPLACED_TERMS =
  'AGREE_TO_THIRD_PARTY_POLICY_REPLACED_TERMS';
export const AGREE_TO_THIRD_PARTY_POLICY_WAIVE_PERIOD_TERMS =
  'AGREE_TO_THIRD_PARTY_POLICY_WAIVE_PERIOD_TERMS';
export const CHOOSE_IF_THIRD_PARTY_POLICY_REPLACED =
  'CHOOSE_IF_THIRD_PARTY_POLICY_REPLACED';
export const SET_THIRD_PARTY_WAIVE_QUESTIONNAIRE_VALIDATION =
  'SET_THIRD_PARTY_WAIVE_QUESTIONNAIRE_VALIDATION';
export const SAVE_PACKAGE_ALLOWS_THIRD_PARTY_POLICY_REPLACEMENT_FLAG =
  'SAVE_PACKAGE_ALLOWS_THIRD_PARTY_POLICY_REPLACEMENT_FLAG';
export const SET_REQUEST_THIRD_PARTY_WAIVE_PERIOD_DETAILS_FLAG =
  'SET_REQUEST_THIRD_PARTY_WAIVE_PERIOD_DETAILS_FLAG';
export const CHECK_THIRD_PARTY_WAIVE_POLICY_PRODUCT_REQUIREMENTS =
  'CHECK_THIRD_PARTY_WAIVE_POLICY_PRODUCT_REQUIREMENTS';

export const ADD_PEEP = 'ADD_PEEP';
export const SELECT_BENEFICIARY = 'SELECT_BENEFICIARY';
export const DESELECT_BENEFICIARY = 'DESELECT_BENEFICIARY';
export const SELECT_INSURED = 'SELECT_INSURED';
export const DESELECT_INSURED = 'DESELECT_INSURED';
export const SAVE_PEEPS = 'SAVE_PEEPS';
export const SAVE_PEEPS_SUCCESS = 'SAVE_PEEPS_SUCCESS';
export const SAVE_PEEPS_FAILURE = 'SAVE_PEEPS_FAILURE';

export const SAVE_BENEFICIARY_NOMINATION = 'SAVE_BENEFICIARY_NOMINATION';
export const SAVE_BENEFICIARIES_DETAILS_NOT_VALID =
  'SAVE_BENEFICIARIES_DETAILS_NOT_VALID';
export const SAVE_BENEFICIARIES_DETAILS_FAILURE =
  'SAVE_BENEFICIARIES_DETAILS_FAILURE';
export const SAVE_BENEFICIARY_NOMINATION_SUCCESSFUL =
  'SAVE_BENEFICIARY_NOMINATION_SUCCESSFUL';

export const SAVE_INSUREDS_NOMINATION = 'SAVE_INSUREDS_NOMINATION';
export const SAVE_INSUREDS_DETAILS_NOT_VALID =
  'SAVE_INSUREDS_DETAILS_NOT_VALID';
export const SAVE_INSUREDS_DETAILS_FAILURE = 'SAVE_INSUREDS_DETAILS_FAILURE';
export const SAVE_INSUREDS_DETAILS_SUCCESS = 'SAVE_INSUREDS_DETAILS_SUCCESS';

export const START_BENEFICIARIES_NOMINATION = 'START_BENEFICIARIES_NOMINATION';
export const INIT_BENEFICIARIES = 'INIT_BENEFICIARIES';
export const ADD_BENEFICIARY_NOMINATION = 'ADD_BENEFICIARY_NOMINATION';
export const REMOVE_BENEFICIARY_NOMINATION = 'REMOVE_BENEFICIARY_NOMINATION';

export const START_INSUREDS_NOMINATION = 'START_INSUREDS_NOMINATION';
export const INIT_INSUREDS = 'INIT_INSUREDS';
export const ADD_INSURED_NOMINATION = 'ADD_INSURED_NOMINATION';
export const REMOVE_INSURED_NOMINATION = 'REMOVE_INSURED_NOMINATION';

export const LINK_REFERRER_CODE = 'LINK_REFERRER_CODE';
export const LINK_BROKER_CODE = 'LINK_BROKER_CODE';
export const LINK_BROKER_CODE_SUCCESS = 'LINK_BROKER_CODE_SUCCESS';
export const SET_BROKER_CONFIG = 'SET_BROKER_CONFIG';
export const SET_BROKER_PORTAL_FLAG = 'SET_BROKER_PORTAL_FLAG';
export const SET_OTP_SMS_TEMPLATE = 'SET_OTP_SMS_TEMPLATE';
export const SET_IEMAS_SUPPORT = 'SET_IEMAS_SUPPORT';
export const VALIDATE_IEMAS_MEMBER_NUMBER = 'VALIDATE_IEMAS_MEMBER_NUMBER';
export const VALIDATE_IEMAS_MEMBER_NUMBER_SUCCESS =
  'VALIDATE_IEMAS_MEMBER_NUMBER_SUCCESS';
export const CHECK_BROKER_CONTEXT_IS_CORRECT =
  'CHECK_BROKER_CONTEXT_IS_CORRECT';

export const LINK_SALE_REQUIREMENTS = 'LINK_SALE_REQUIREMENTS';
export const LINK_SALE_REQUIREMENTS_SUCCESS = 'LINK_SALE_REQUIREMENTS_SUCCESS';

export const SET_SALE_REQUIREMENTS_PROVIDED = 'SET_SALE_REQUIREMENTS_PROVIDED';
export const LINK_SALE_REQUIREMENTS_ERROR = 'LINK_SALE_REQUIREMENTS_ERROR';

export const LINK_EXISTING_REFERRER_CODE = 'LINK_EXISTING_REFERRER_CODE';
export const LINK_EXISTING_BROKER_CODE = 'LINK_EXISTING_BROKER_CODE';
export const SET_REFERRER_CODE = 'SET_REFERRER_CODE';
export const SET_WIN_WIN_DETAILS = 'SET_WIN_WIN_DETAILS';
export const LINK_EXISTING_REFERRER_TO_SALE = 'LINK_EXISTING_REFERRER_TO_SALE';
export const LINK_REFERRER_TO_SALE = 'LINK_REFERRER_TO_SALE';
export const REQUEST_REFERRER_INFO = 'REQUEST_REFERRER_INFO';
export const SET_REFERRER_STATUS = 'SET_REFERRER_STATUS';
export const SET_SENDER_REFERENCE = 'SET_SENDER_REFERENCE ';

export const COMPLETE_SALE = 'COMPLETE_SALE';
export const COMPLETE_SALE_SUCCESS = 'COMPLETE_SALE_SUCCESS';
export const COMPLETE_SALE_VALIDATION_FAILURE =
  'COMPLETE_SALE_VALIDATION_FAILURE';
export const COMPLETE_SALE_FAILURE = 'COMPLETE_SALE_FAILURE';
export const COMPLETE_SALE_BUSY = 'COMPLETE_SALE_BUSY';
export const SET_SALE_COMPLETE = 'SET_SALE_COMPLETE';

export const SHOW_CALL_ME_BACK = 'SHOW_CALL_ME_BACK';
export const SAVE_CALL_ME_BACK_DETAILS = 'SAVE_CALL_ME_BACK_DETAILS';
export const SAVE_CALL_ME_BACK_DETAILS_FAILURE =
  'SAVE_CALL_ME_BACK_DETAILS_FAILURE';
export const SAVE_CALL_ME_BACK_DETAILS_SUCCESS =
  'SAVE_CALL_ME_BACK_DETAILS_SUCCESS';
export const OPEN_CALL_ME_BACK_MENU = 'OPEN_CALL_ME_BACK_MENU';
export const CLOSE_CALL_ME_BACK_MENU = 'CLOSE_CALL_ME_BACK_MENU';

export const RESET = 'RESET';

export const REQUEST_MORE = 'REQUEST_MORE';
export const REQUEST_MORE_SUCCESS = 'REQUEST_MORE_SUCCESS';
export const REQUEST_MORE_FAILURE = 'REQUEST_MORE_FAILURE';

export const SHOULD_LINK_TO_CAMPAIGN = 'SHOULD_LINK_TO_CAMPAIGN';
export const LINK_TO_CAMPAIGN = 'LINK_TO_CAMPAIGN';
export const LINK_TO_CAMPAIGN_SUCCESS = 'LINK_TO_CAMPAIGN_SUCCESS';
export const LINK_TO_CAMPAIGN_ERROR = 'LINK_TO_CAMPAIGN_ERROR';
export const LINK_TO_CAMPAIGN_NO_CAMPAIGN = 'LINK_TO_CAMPAIGN_NO_CAMPAIGN';
export const SET_CAMPAIGN_COOKIE = 'SET_CAMPAIGN_COOKIE';
export const SAVE_UTM_PROPS = 'SAVE_UTM_PROPS';

export const BEGIN_REFERRER_SIGNUP = 'BEGIN_REFERRER_SIGNUP';
export const SIGNUP_AS_REFERRER = 'SIGNUP_AS_REFERRER';
export const LINK_REFERRER_SUCCESS = 'LINK_REFERRER_SUCCESS';
export const LINK_REFERRER_FAILURE = 'LINK_REFERRER_FAILURE';
export const PROVIDE_INPUT = 'PROVIDE_INPUT';
export const ACCEPT_REFERRAL_TERMS = 'ACCEPT_REFERRAL_TERMS';
export const REFER_CONTACT = 'REFER_CONTACT';
export const SAVE_REFFERAL_REWARD_ALLOCATION =
  'SAVE_REFFERAL_REWARD_ALLOCATION';
export const ANSWER_PAYMENT_OPTION = 'ANSWER_PAYMENT_OPTION';

export const UPDATE_SELECTED_PACKAGE = 'UPDATE_SELECTED_PACKAGE';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const GET_NEW_PRODUCT_LIST_FOR_PACKAGE =
  'GET_NEW_PRODUCT_LIST_FOR_PACKAGE';

export const SET_MULTI_POLICY_SUPPORT = 'SET_MULTI_POLICY_SUPPORT';
export const PREP_FOR_MULTIPLE_POLICIES = 'PREP_FOR_MULTIPLE_POLICIES';
export const RESET_WITH_EXISTING_OWNER = 'RESET_WITH_EXISTING_OWNER';

export const RESET_STATE_TO_PREVIOUS_PACKAGE =
  'RESET_STATE_TO_PREVIOUS_PACKAGE';
export const RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS =
  'RELOAD_OLD_PACKAGE_SELECTED_PRODUCTS';
export const COPY_PACKAGE_SELECTED_PRODUCTS = 'COPY_PACKAGE_SELECTED_PRODUCTS';
export const CONTINUE_WITH_CHANGED_PACKAGE = 'CONTINUE_WITH_CHANGED_PACKAGE';
export const SELECT_CHANGED_PACKAGE_PRODUCTS =
  'SELECT_CHANGED_PACKAGE_PRODUCTS';
export const CLEAR_OLD_PACKAGE_SELECTIONS = 'CLEAR_OLD_PACKAGE_SELECTIONS';

export const AUTO_POPULATE_REFERRER_DETAILS = 'AUTO_POPULATE_REFERRER_DETAILS';
export const CONNECT_FRIENDS_SETUP = 'CONNECT_FRIENDS_SETUP';
export const RESET_FRIEND_REFERRAL_FORM = 'RESET_FRIEND_REFERRAL_FORM';
export const SET_REFERRAL_PACKAGE = 'SET_REFERRAL_PACKAGE';
export const SET_REFERRAL_ANSWER = 'SET_REFERRAL_ANSWER';
export const SAVE_REFERRAL_ENTRIES = 'SAVE_REFERRAL_ENTRIES';
export const SELECT_REFERRAL_OPTION_TYPE = 'SELECT_REFERRAL_OPTION_TYPE';
export const ADD_REFERRAL_OPTION_RECEPIENT = 'ADD_REFERRAL_OPTION_RECEPIENT';
export const DELETE_REFERRAL = 'DELETE_REFERRAL';

export const INIT_DETAILS_CAPTURE = 'INIT_DETAILS_CAPTURE';
export const INVALID_DETAILS_CAPTURE = 'INVALID_DETAILS_CAPTURE';
export const SET_DETAILS_CAPTURE_PRODUCTS = 'SET_DETAILS_CAPTURE_PRODUCTS';
export const DETAILS_CAPTURE_SUBMIT_UNDERWRITING =
  'DETAILS_CAPTURE_SUBMIT_UNDERWRITING';
export const DETAILS_CAPTURE_SUBMIT_UNDERWRITING_SUCCESS =
  'DETAILS_CAPTURE_SUBMIT_UNDERWRITING_SUCCESS';
export const DETAILS_CAPTURE_SUBMIT_PEEPS = 'DETAILS_CAPTURE_SUBMIT_PEEPS';
export const DETAILS_CAPTURE_SUBMIT_PEEPS_SUCCESS =
  'DETAILS_CAPTURE_SUBMIT_PEEPS_SUCCESS';

export const REHYDRATE_INTERACTION_SUCCESS = 'REHYDRATE_INTERACTION_SUCCESS';

export const REHYDRATE_INTERACTION_FAILURE = 'REHYDRATE_INTERACTION_FAILURE';

export const REHYDRATE_INTERACTION = 'REHYDRATE_INTERACTION';

export const REHYDRATION_COMPLETE = 'REHYDRATION_COMPLETE';

export const SAVE_LAST_ROUTE = 'SAVE_LAST_ROUTE';

export const SET_REHYDRATION_URL = 'SET_REHYDRATION_URL';
export const SET_REHYDRATION_URL_SUCCESS = 'SET_REHYDRATION_URL_SUCCESS';
export const SET_REHYDRATION_URL_FAILURE = 'SET_REHYDRATION_URL_FAILURE';
export const SEND_REHYDRATE_LINK_SUCCESS = 'SEND_REHYDRATE_LINK_SUCCESS';
export const SEND_REHYDRATE_LINK_FAILURE = 'SEND_REHYDRATE_LINK_FAILURE';
export const SEND_REHYDRATION_LINK = 'SEND_REHYDRATION_LINK';

export const ENTER_COMPETITION = 'CONTACT_DETAILS';
export const OPT_OUT_OF_COMPETITION = 'OPT_OUT_OF_COMPETITION';
export const SET_SHOW_CONTACT_DETAILS_POPUP_FLAG =
  'SET_SHOW_CONTACT_DETAILS_POPUP_FLAG';
export const VIEWED_CONTACT_FORM = 'VIEWED_CONTACT_FORM';
export const FETCH_ACTIVE_USERS = 'FETCH_ACTIVE_USERS';
export const SAVE_ACTIVE_USERS = 'SAVE_ACTIVE_USERS';

export const SUPPORTS_IEMAS_SALARY_DEDUCTION_PAYMENT_TYPE =
  'SUPPORTS_IEMAS_SALARY_DEDUCTION_PAYMENT_TYPE';
export const ONLY_DEBIT_ORDER_PAYMENT_TYPE_SUPPORTED =
  'ONLY_DEBIT_ORDER_PAYMENT_TYPE_SUPPORTED';
export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE';

export const SAVE_ROP_PRICING = 'SAVE_ROP_PRICING';

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';
export const OTP_VALIDATED = 'OTP_VALIDATED';
export const THIRD_PARTY_OTP_VALIDATED = 'THIRD_PARTY_OTP_VALIDATED';
export const WILL_SEND_RECORD_OF_ADVICE_OTP = 'WILL_SEND_RECORD_OF_ADVICE_OTP';

export const SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAIL =
  'SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAIL';
export const SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAILS =
  'SET_CURRENT_EXTENDED_FAMILY_INSURED_DETAILS';
export const SETTING_CURRENT_INSURED_DETAIL = 'SETTING_CURRENT_INSURED_DETAIL';
export const VALIDATE_CURRENT_INSURED_DETAIL =
  'VALIDATE_CURRENT_INSURED_DETAIL';
export const VALIDATED_CURRENT_INSURED = 'VALIDATED_CURRENT_INSURED';
export const ADD_CURRENT_EXTENDED_FAMILY_INSURED =
  'ADD_CURRENT_EXTENDED_FAMILY_INSURED';
export const EDIT_EXTENDED_FAMILY_INSURED =
  'ADD_CURRENT_EXTENDED_FAMILY_INSURED';
export const REMOVE_EXTENDED_FAMILY_INSURED = 'REMOVE_EXTENDED_FAMILY_INSURED';
export const SELECT_EXTENDED_FAMILY_PRODUCT = 'SELECT_EXTENDED_FAMILY_PRODUCT';
export const UPDATE_FAMILY_MEMBER_PRICES = 'UPDATE_FAMILY_MEMBER_PRICES';
export const FETCHING_CURRENT_INSURED_PRICING =
  'FETCHING_CURRENT_INSURED_PRICING';
export const ADD_EXTENDED_FAMILY_WITH_SELECTED_FAMILY_MEMBERS =
  'ADD_EXTENDED_FAMILY_WITH_SELECTED_FAMILY_MEMBERS';
export const SET_EXTENDED_FAMILY_PRICING = 'SET_EXTENDED_FAMILY_PRICING';
export const CURRENT_INSURED_REFRESHED = 'CURRENT_INSURED_REFRESHED';
export const FETCH_EXTENDED_FAMILY_MEMBER_PRICE_ERROR =
  'FETCH_EXTENDED_FAMILY_MEMBER_PRICE_ERROR';
export const EDIT_EFF_INSURED = 'EDIT_EFF_INSURED';
export const UPDATE_EFF_INSURED = 'UPDATE_EFF_INSURED';
export const DELETE_EFF_INSURED = 'DELETE_EFF_INSURED';
export const DELETING_EFF_INSURED = 'DELETING_EFF_INSURED';
export const OVERWRITE_EFF_MEMBER_PRICES = 'OVERWRITE_EFF_MEMBER_PRICES';
export const OPEN_EFF_INSURED_EDITOR = 'OPEN_EFF_INSURED_EDITOR';
export const TOGGLE_EFF_SELECTABLE = 'TOGGLE_EFF_SELECTABLE';
export const REHYDRATE_EXTENDED_FAMILY_INSUREDS =
  'REHYDRATE_EXTENDED_FAMILY_INSUREDS';
export const TOGGLE_EFF_SUCCESS = 'TOGGLE_EFF_SUCCESS';
export const SAVE_LOCAL_STORAGE_STATUS = 'SAVE_LOCAL_STORAGE_STATUS';
export const FUNERAL_COVER_AMT_CHANGED = 'FUNERAL_COVER_AMT_CHANGED';
export const SAVE_LAST_LOCATION = 'SAVE_LAST_LOCATION';

export const TOGGLE_HAS_NO_SPOURSE_OR_CHILDREN_UNDER_21 =
  'TOGGLE_HAS_NO_SPOURSE_OR_CHILDREN_UNDER_21';

export const ACCEPT_OMART_PRODUCT_INFORMATION =
  'ACCEPT_OMART_PRODUCT_INFORMATION';
export const ACCEPT_SIMPLY_PRODUCT_INFORMATION =
  'ACCEPT_SIMPLY_PRODUCT_INFORMATION';

export const PERSAL_START_AFFORDABILITY_CHECK =
  'PERSAL_START_AFFORDABILITY_CHECK';
export const PERSAL_FINISH_AFFORDABILITY_CHECK =
  'PERSAL_FINISH_AFFORDABILITY_CHECK';
export const PERSAL_START_MANDATE_UPLOAD = 'PERSAL_START_MANDATE_UPLOAD';
export const PERSAL_FINISH_MANDATE_UPLOAD = 'PERSAL_FINISH_MANDATE_UPLOAD';
export const PERSAL_START_MANDATE_DOWNLOAD = 'PERSAL_START_MANDATE_DOWNLOAD';
export const PERSAL_FINISH_MANDATE_DOWNLOAD = 'PERSAL_FINISH_MANDATE_DOWNLOAD';
export const PERSAL_START_ELECTRONIC_MANDATE_REQUEST =
  'PERSAL_START_ELECTRONIC_MANDATE_REQUEST';
export const PERSAL_FINISH_ELECTRONIC_MANDATE_REQUEST =
  'PERSAL_FINISH_ELECTRONIC_MANDATE_REQUEST';
export const PERSAL_SELECT_MANDATE_TYPE = 'PERSAL_SELECT_MANDATE_TYPE';
export const PERSAL_START_CONFIRM_ELECTRONIC_MANDATE_SIGNED =
  'PERSAL_START_CONFIRM_ELECTRONIC_MANDATE_SIGNED';
export const PERSAL_FINISH_CONFIRM_ELECTRONIC_MANDATE_SIGNED =
  'PERSAL_FINISH_CONFIRM_ELECTRONIC_MANDATE_SIGNED';
export const PERSAL_ONLOAD_RESTART = 'PERSAL_ONLOAD_RESTART';
export const PERSAL_RESTART = 'PERSAL_RESTART';
export const PERSAL_QA_RESTART = 'PERSAL_QA_RESTART';
export const PERSAL_ERROR = 'PERSAL_ERROR';
export const UPDATE_BANKING_DETAILS = 'UPDATE_BANKING_DETAILS';
export const PERSAL_SET_COLLECTION_DATES = 'PERSAL_SET_COLLECTION_DATES';

export const DELAYED_SCROLL_TOP = 'DELAYED_SCROLL_TOP';

export const GET_BANK_ACCOUNT_VERIFICATION = 'GET_BANK_ACCOUNT_VERIFICATION';
export const SET_BANK_ACCOUNT_VERIFICATION = 'SET_BANK_ACCOUNT_VERIFICATION';
export const POLL_BANK_ACCOUNT_VERIFICATION = 'POLL_BANK_ACCOUNT_VERIFICATION';
export const SALARY_DEDUCTION_RESERVE_TRANSACTION_NUMBER =
  'SALARY_DEDUCTION_RESERVE_TRANSACTION_NUMBER';
export const SALARY_DEDUCTION_TRANSACTION_NUMBER_RESERVED =
  'SALARY_DEDUCTION_TRANSACTION_NUMBER_RESERVED';
export const SET_SALARY_DEDUCTION_STAGE = 'SET_SALARY_DEDUCTION_STAGE';
export const SET_SALARY_DEDUCTION_PAYMENT_DETAILS =
  'SET_SALARY_DEDUCTION_PAYMENT_DETAILS';
export const SALARY_DEDUCTION_START_MANDATE_DOWNLOAD =
  'SALARY_DEDUCTION_START_MANDATE_DOWNLOAD';
export const SALARY_DEDUCTION_FINISH_MANDATE_DOWNLOAD =
  'SALARY_DEDUCTION_FINISH_MANDATE_DOWNLOAD';
export const SALARY_DEDUCTION_ERROR = 'SALARY_DEDUCTION_ERROR';
export const TOGGLE_VOICE_MANDATE_TERM = 'TOGGLE_VOICE_MANDATE_TERM';
export const VALIDATE_BROKER_COMPLIANCE = 'VALIDATE_BROKER_COMPLIANCE';

export const COMPLIANCE_DOC_UPLOAD_ERROR = 'COMPLIANCE_DOC_UPLOAD_ERROR';
export const FINISH_COMPLIANCE_DOC_UPLOAD = 'FINISH_COMPLIANCE_DOC_UPLOAD';
export const COMPLIANCE_DOC_UPLOAD = 'COMPLIANCE_DOC_UPLOAD';

export const REHYDRATE_BROKER_COMPLIANCE = 'REHYDRATE_BROKER_COMPLIANCE';
export const GET_BROKER_COMPLIANCE_FROM_INTERACTION =
  'GET_BROKER_COMPLIANCE_FROM_INTERACTION';
export const SAVE_COMPLIANCE_PAYMENT_TYPE = 'SAVE_COMPLIANCE_PAYMENT_TYPE';
export const SET_FILE_UPLOAD_PROGRESS = 'SET_FILE_UPLOAD_PROGRESS';

export const TOGGLE_FUNERAL_COVER_DISCLAIMER =
  'TOGGLE_FUNERAL_COVER_DISCLAIMER';
export const TOGGLE_FUNERAL_COVER_DISCLAIMER_ACCEPTED =
  'TOGGLE_FUNERAL_COVER_DISCLAIMER_ACCEPTED';

// -------- Replace and Save feataure --------

export const REPLACE_AND_SAVE_INSURED_PERSON_ADDED =
  'REPLACE_AND_SAVE_INSURED_PERSON_ADDED';
export const REPLACE_AND_SAVE_REPLACEMENT_POLICY_ADDED =
  'REPLACE_AND_SAVE_REPLACEMENT_POLICY_ADDED';
export const REPLACE_AND_SAVE_ADD_REPLACEMENT_POLICY =
  'REPLACE_AND_SAVE_ADD_REPLACEMENT_POLICY';
export const REPLACE_AND_SAVE_REMOVE_REPLACEMENT_POLICY =
  'REPLACE_AND_SAVE_REMOVE_REPLACEMENT_POLICY';
export const REPLACE_AND_SAVE_SET_ENABLED = 'REPLACE_AND_SAVE_SET_ENABLED';
export const REPLACE_AND_SAVE_ADD_POLICY_NEXT_STEP =
  'REPLACE_AND_SAVE_ADD_POLICY_NEXT_STEP';
export const REPLACE_AND_SAVE_REMOVE_POLICY_NEXT_STEP =
  'REPLACE_AND_SAVE_REMOVE_POLICY_NEXT_STEP';
export const REPLACE_AND_SAVE_ADDING_INSURED_PERSON =
  'REPLACE_AND_SAVE_ADDING_INSURED_PERSON';
export const REPLACE_AND_SAVE_SET_REPLACEMENT_POLICY_DATA =
  'REPLACE_AND_SAVE_SET_REPLACEMENT_POLICY_DATA';
export const REPLACE_AND_SAVE_SET_CANCELLATION_LETTER_DATA =
  'REPLACE_AND_SAVE_SET_CANCELLATION_LETTER_DATA';
export const REPLACE_AND_SAVE_SHOW_REPLACEMENT_POLICIES_PAGE =
  'REPLACE_AND_SAVE_SHOW_REPLACEMENT_POLICIES_PAGE';
export const REPLACE_AND_SAVE_SET_REPLACEMENT_POLICIES_SAVINGS_INFORMATION =
  'REPLACE_AND_SAVE_SET_REPLACEMENT_POLICIES_SAVINGS_INFORMATION';
export const AGREE_TO_REPLACE_AND_SAVE_POLICY_INFORMATION = 'AGREE_TO_REPLACE_AND_SAVE_POLICY_INFORMATION';