import React, { Fragment, PureComponent } from 'react';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { getSelectedPackageIdOrDefault } from '../../common/getPackageIdFromSlug';
import { answerQuestion as answerQuestionActionCreator } from '../../actions/answers';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import R from 'ramda';
import { Button, WallOfTextContainer } from '@simply-fin-services/astronomix3';

class Cession extends PureComponent {
  constructor() {
    super();
    this.state = {
      showCession: false,
    };
    this.toggleCession = this.toggleCession.bind(this);
  }

  toggleCession() {
    this.setState({ showCession: !this.state.showCession });
    this.props.answerQuestion(
      this.props.packageId,
      'has-cession',
      !this.state.showCession ? 'Yes' : 'No'
    );
  }

  render() {
    return (
      <WallOfTextContainer>
        <p>
          Please click the button below if you wish to cede your life benefit as
          security for a debt (This does not affect other benefits on this
          policy).
        </p>
        <div className="space-above-2"></div>
        <Button onClick={this.toggleCession}>Cede My Policy</Button>
        <br />
        <br />
        {this.state.showCession && (
          <Fragment>
            <p>
              You accept that by ceding my life benefit, the company to whom you
              are ceding this benefit (“cessionary”) will be paid the benefit on
              your death, not my nominated beneficiaries. As this is a security
              (collateral) cession, the cessionary will only be paid the amount
              needed to settle the outstanding debt. If the life benefit payable
              is more than the value of the outstanding debt, the remainder will
              be paid to the beneficiaries you have nominated above. You remain
              responsible for paying the premiums.
            </p>
            <p>
              We will inform the cessionary that you have ceded this policy. If
              they do not accept this policy as security for their loan to you,
              we will let you know. We will also notify them of any changes you
              make to the policy, as well as of any missed payments or if the
              policy lapses or is cancelled by you.
            </p>
            <p>
              Once the policy has been ceded by you, the cession can only be
              cancelled at the written request of the cessionary, not by the
              policyholder.
            </p>
            <p>
              We do not warrant that this policy meets the requirements of a
              credit life policy as defined under the National Credit Act or the
              Policyholder Protection Rules.
            </p>
            <QuestionPageQuestions {...this.props.cessionQuestionPageProps} />
          </Fragment>
        )}
      </WallOfTextContainer>
    );
  }
}

const mapStateToProps = (state) => {
  const cessionQAQuestions = state.getIn([
    'questionPages',
    'cession-questionnaire',
    'questions',
  ]);
  const optionalPageQuestions = fromJS([]);
  const { walkedPage, isValid, questions, answers } = stateToQuestionnaireProps(
    state,
    cessionQAQuestions,
    optionalPageQuestions
  );
  return {
    packageId: getSelectedPackageIdOrDefault(state),
    cessionQuestionPageProps: {
      walkedPage,
      isValid,
      questions,
      answers,
      pageQuestions: cessionQAQuestions,
      optionalPageQuestions,
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    answerQuestion: R.curry((packageId, question, answer) => {
      return dispatch(answerQuestionActionCreator(packageId, question, answer));
    }),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, dispatchProps, ownProps, {
    cessionQuestionPageProps: Object.assign(
      stateProps.cessionQuestionPageProps,
      {
        answerQuestion: dispatchProps.answerQuestion(stateProps.packageId),
      }
    ),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Cession);
