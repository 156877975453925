import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';

export const WaivePeriodConditions = () => (
  <div className="space-below">
    <p>
      Subject to the following conditions, we can waive the waiting period that
      normally applies to the funeral benefit (but not the other benefits):
    </p>
    <div>
      <ol className="indent">
        <li>
          Your existing funeral policy must have been cancelled within two months of
          taking out this policy, and must have been in force for at least 6 months before it
          was cancelled.
        </li>
        <li>
          The policy must be from a licensed insurance company recognised by
          the Prudential Authority.
        </li>
        <li>
          The waiting period will only be waived for lives (people) that were
          covered by the funeral policy you are replacing and this will be
          checked at claims stage.
        </li>
        <li>
          The Sums Assured on the old policy must be the
          same as or greater than what you are taking out with OMART.
        </li>
        <li>
          You must cancel your existing policy (if it’s not already cancelled),
          and you cannot reinstate it later.
        </li>
      </ol>
    </div>
  </div>
);

class ReplacePolicyTerms extends PureComponent {
  render() {
    const { terms, children } = this.props;
    return (
      <div>
        {R.addIndex(R.map)(
          (d, i) => (
            <p key={i}>{d}</p>
          ),
          terms
        )}
        <div>{children}</div>
      </div>
    );
  }
}

ReplacePolicyTerms.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const mapStateToProps = state => {
  const packageSlug = state.get('selectedPackageSlug');
  const termsId = state.getIn(['packages', packageSlug, 'replacementTerms']);
  return {
    terms: state.getIn(['terms', termsId, 'detail']).toJS(),
  };
};

export default connect(mapStateToProps)(ReplacePolicyTerms);
