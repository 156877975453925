import { fromJS } from 'immutable';
import { ROUTES } from '../../config/constants';
import { PAGE_ACTION_TYPES } from '../../.shared/constants';
import { push } from 'connected-react-router';
import { completeQuestionPage } from '../../actions/questionPageActions';
import { delayedScrollToTop } from '../../actions/app';

import { getBenefits } from '../peeps/getSelectedProductIds';

const checkActionType = (page, type) => page.get('type') === type;
const isPageAction = (page) => checkActionType(page, PAGE_ACTION_TYPES.PAGE);
const isRouteAction = (page) => checkActionType(page, PAGE_ACTION_TYPES.ROUTE);

export const getNextPageAction = (
  questionare,
  currentPageId,
  packageUrlSlug,
  allowDeferredUnderwriting,
  brokerCanDoPeepsFollowUp,
  state
) => {
  
  const isLastPage = questionare.last() === currentPageId;
  if (isLastPage) {
    if (allowDeferredUnderwriting && !brokerCanDoPeepsFollowUp) {
      return fromJS({
        type: PAGE_ACTION_TYPES.ROUTE,
        url: ROUTES.toSimplifiedTerms(packageUrlSlug),
      });
    }
    
    if(state){
      const {
        hasLifeBenefit,
        hasDisabilityBenefit,
        hasFuneralbenefit,
        hasEffBenefit,
      } = getBenefits(state, packageUrlSlug);
      if (
        !hasLifeBenefit &&
        !hasDisabilityBenefit &&
        !hasFuneralbenefit &&
        hasEffBenefit
      ) {
        return fromJS({
          type: PAGE_ACTION_TYPES.ROUTE,
          url: ROUTES.toSimplifiedTerms(packageUrlSlug),
        });
      }
    }
    
    return fromJS({
      type: PAGE_ACTION_TYPES.ROUTE,
      url: ROUTES.toPeepsCapture(packageUrlSlug),
    });
  }
  const nextPageIndex = questionare.indexOf(currentPageId) + 1;
  const nextQuestionPageId = questionare.get(nextPageIndex);
  return fromJS({
    type: PAGE_ACTION_TYPES.PAGE,
    questionPageId: nextQuestionPageId,
  });
};

const getPageActionDispatcher = (next, packageUrlSlug) => {
  const questionPageId = next.get('questionPageId');
  const url = ROUTES.toDetail(packageUrlSlug, questionPageId);
  return (dispatch) => () => {
    dispatch(push(url));
    dispatch(delayedScrollToTop());
  };
};

export default (
  packageId,
  packageUrlSlug,
  questionare,
  currentPageId,
  allowDeferredUnderwriting,
  brokerCanDoPeepsFollowUp,
  state
) => {
  const next = getNextPageAction(
    questionare,
    currentPageId,
    packageUrlSlug,
    allowDeferredUnderwriting,
    brokerCanDoPeepsFollowUp,
    state
  );
  if (isPageAction(next)) {
    return getPageActionDispatcher(next, packageUrlSlug);
  }
  if (isRouteAction(next)) {
    return (dispatch) => () => {
      dispatch(completeQuestionPage(currentPageId));
      return dispatch(push(next.get('url')));
    };
  }
  return () => () => {
    throw new Error(`Page Action type handler not implemented: ${next}`);
  };
};
