// This file gets copied from app/config to server/config
// To make changes make them in app/config otherwise they will get overwritten
export const SITE_NAME = 'Simply';
export const SERVICE_NAME = 'asterix';
export const DOMAIN = 'simply.co.za';

const routes = {
  HOME: '/',
  LOGIN: '/login',
  NOT_FOUND: '/notfound',
  ERROR: '/error',
  GET_A_QUOTE: '/get-a-quote',
  PACKAGES: '/packages/',
  PACKAGES_P: '/packages/:urlSlug',
  QUOTE: '/quote/',
  QUOTE_P: '/quote/:packageUrlSlug',
  QUOTE_FAMILY: '/quote/simply-family-combo',
  QUOTE_DOMESTIC: '/quote/simply-employer-combo',
  QUOTE_GROUP: '/packages/group-cover',
  QUOTE_LANDING: '/prefilled-quote',
  BROKER_LANDING: '/broker/:brokerCode',
  DETAIL: '/detail/',
  DETAIL_P: '/detail/:packageUrlSlug/:questionPageUrlSlug',
  CUSTOMIZE: '/customization/',
  CUSTOMIZE_P: '/customization/:packageUrlSlug',
  NEARLY_THERE: '/nearly-there/',
  SALE_REQUIREMENTS: '/sale-requirements',
  NEARLY_THERE_P: '/nearly-there/:packageUrlSlug',
  NOT_ELIGIBLE: '/not-eligible',
  FEATURES: '/features/',
  FEATURES_P: '/features/:packageUrlSlug',
  BANKING_DETAILS: '/banking-details/',
  BANKING_DETAILS_P: '/banking-details/:packageUrlSlug',
  SIMPLIFIED_TERMS: '/terms/',
  SIMPLIFIED_INSURED_TERMS: '/insured-terms/',
  SIMPLIFIED_TERMS_P: '/terms/:packageUrlSlug',
  SIMPLIFIED_INSURED_TERMS_P: '/insured-terms/:packageUrlSlug',
  PEEPS: '/peeps',
  THANK_YOU: '/thank-you/',
  THANK_YOU_P: '/thank-you/:packageUrlSlug',
  CAMPAIGN_P: '/campaign/:campaignId',
  CAMPAIGN_2_P: '/campaign/:sourceId/:campaignId',
  REFERRAL_SIGNUP: '/refer-now',
  REFERRER_LOGIN: '/referrer/login',
  REFERRAL_HOMEPAGE: '/refer-your-friends-and-family/',
  REFERRAL_HOMEPAGE_P: '/refer-your-friends-and-family/:token',
  REFERRAL_HOMEPAGE_WITH_DEETS_P:
    '/refer-your-friends-and-family/:referralCode/:name',
  REFERRAL_URL: '/get-some-great-life-insurance/',
  REFERRAL_URL_P: '/get-some-great-life-insurance/:referralCode',
  REFERRAL_URL_WITH_DEETS_P:
    '/get-some-great-life-insurance/:referralCode/:name',
  REFERRAL_TERMS: '/referral-terms-and-conditions',
  CONNECT_FRIENDS_PAGE: '/share-the-love',
  SIMPLY_MONEY: '/simplymoney',
  DETAILS_CAPTURE_LANDING: '/welcome-to-simply/login',
  DETAILS_CAPTURE_INIT: '/welcome-to-simply',
  DETAILS_CAPTURE: '/welcome-to-simply/tell-us-more-us-about-yourself',
  DETAILS_CAPTURE_PEEPS:
    '/welcome-to-simply/tell-us-more-about-your-loved-ones',
  DETAILS_CAPTURE_THANK_YOU: '/welcome-to-simply/thank-you',
  WELCOME_BACK: '/welcome-back',
  TELL_US_MORE_ABOUT_YOUR_LOVED_ONES: '/tell-us-more-about-your-loved-ones/',
  DETAILS_CAPTURE_PEEPS_P:
    '/tell-us-more-about-your-loved-ones/:packageUrlSlug',

  VALIDATE_QUESTION_ANSWER: '/quote/validate-question-answer',
  REPLACE_AND_SAVE_DASHBOARD: '/replace-and-save',
};

routes.toPackages = (urlSlug) => `${routes.PACKAGES}${urlSlug}`;
routes.toDetail = (packageSlug, questionPageUrlSlug) =>
  `${routes.DETAIL}${packageSlug}/${questionPageUrlSlug}`;
routes.toQuote = (packageSlug) => `${routes.QUOTE}${packageSlug}`;
routes.toFeatures = (packageSlug) => `${routes.FEATURES}${packageSlug}`;
routes.toCustomisation = (packageSlug) => `${routes.CUSTOMIZE}${packageSlug}`;
routes.toNearlyThere = (packageSlug) => `${routes.NEARLY_THERE}${packageSlug}`;
routes.toSimplifiedTerms = (packageSlug) =>
  `${routes.SIMPLIFIED_TERMS}${packageSlug}`;
routes.toSimplifiedInsuredTerms = packageSlug =>
  `${routes.SIMPLIFIED_INSURED_TERMS}${packageSlug}`;

routes.toPeepsCapture = packageSlug =>
  `${routes.TELL_US_MORE_ABOUT_YOUR_LOVED_ONES}${packageSlug}`;
routes.toBankingDetails = (packageSlug) =>
  `${routes.BANKING_DETAILS}${packageSlug}`;
routes.toThankYou = (packageSlug) => `${routes.THANK_YOU}${packageSlug}`;
routes.toReferralUrl = (token) => `${routes.REFERRAL_URL}${token}`;
routes.toReferralUrlWithDeets = (referralCode, name) =>
  `${routes.REFERRAL_URL}${referralCode}/${name}`;
routes.toReferrerHomePage = (token) => `${routes.REFERRAL_HOMEPAGE}${token}`;
routes.toReferrerHomePageWithDeets = (referralCode, name, referralUrl) =>
  `${routes.REFERRAL_HOMEPAGE}${referralCode}/${name}?referralUrl=${referralUrl}`;

export const ROUTES = routes;

export const GCDROUTES = [
  '/_ah/health',
  '/healthz',
  '/_ah/background',
  '/stackdriver/warning',
  '/stackdriver/warning',
];

export const DEV_TOOLS_ROUTE_PREFIX = '/dev';
const devRoute = (route) => `${DEV_TOOLS_ROUTE_PREFIX}${route}`;
export const DEV_TOOLS_ROUTES = {
  ENABLE_LOCAL_STORAGE: devRoute('/enable-localstorage'),
  DISABLE_LOCAL_STORAGE: devRoute('/disable-localstorage'),
};

export const API_ROUTE_PREFIX = '/api';
const apiRoute = (route) => `${API_ROUTE_PREFIX}${route}`;
export const API_ROUTES = {
  AUTHENTICATE: apiRoute('/auth'),
  DO_AVSR_ACCOUNT_VALIDATION: apiRoute('/bankingdetails/validate'),
  POLL_AVSR_ACCOUNT_VALIDATION: apiRoute('/bankingdetails/validate/poll'),
  SAVE_POPI_PRODUCT_INFORMATION: apiRoute('/popi/product-information'),
  SEND_OTP: apiRoute('/auth/otp/send'),
  VALIDATE_OTP: apiRoute('/auth/otp/validate'),
  CATALOGUE: {
    GET_PACKAGES: apiRoute('/catalogue/packages'),
  },
  SEND_SALE_TO_QA: apiRoute('/send-to-qa'),
  SEND_SALE_FOR_CORRECTIONS: apiRoute('/broker/send-for-corrections'),
  REJECT_SALE: apiRoute('/broker/reject-sale'),
  GET_RECOMMENDATION: apiRoute('/quote/recommendation'),
  GET_RECOMMENDATION_FROM_ANSWERS: apiRoute(
    '/quote/recommendation-from-answers'
  ),
  ANSWERQUESTION: apiRoute('/quote/answer'),
  VALIDATE_QUESTION_ANSWER: apiRoute('/quote/validate-question-answer'),
  ANSWER_UNDERWRITING_QUESTION: apiRoute('/quote/underwriting-answer'),
  DOWNLOAD_QUOTE: apiRoute('/quote/download'),
  BANKING_DETAILS: apiRoute('/bankingdetails'),
  BENEFICIARIES: apiRoute('/beneficiaries'),
  INSUREDS: apiRoute('/insureds'),
  UNDERWRITING_BENEFICIARIES: apiRoute('/underwriting/beneficiaries'),
  UNDERWRITING_INSUREDS: apiRoute('/underwriting/insureds'),
  SELECTED_PRODUCTS: apiRoute('/selected-products'),
  SELECTED_PACKAGE: apiRoute('/selected-package'),
  POST_QUOTE_NOTIFICAITON: apiRoute('/quote-notification'),
  SELECT_ROP: apiRoute('/select-rop'),
  VALIDATE_EXTENDED_FAMILY_MEMBER: apiRoute('/validate-extended-family-member'),
  ADD_EXTENDED_FAMILY_MEMBER: apiRoute('/add-extended-family-member'),
  COMPLETE_SALE: apiRoute('/complete-sale'),
  TERMS_AND_CONDITIONS: apiRoute('/terms-and-conditions'),
  CALL_ME_BACK_DETAILS: apiRoute('/call-me-back'),
  REQUEST_MORE_COVER: apiRoute('/i-want-more'),
  LINK_CAMPAIGN: apiRoute('/link-campaign'),
  FIND_REFERRER: apiRoute('/referrer/login/find'),
  REFERRER_LOGIN: apiRoute('/referrer/login'),
  REFERRER_SIGNUP: apiRoute('/referrer'),
  REFERRER_ACCEPT_TERMS: apiRoute('/referrer/terms'),
  REFERRER_LINK_TO_SALE: apiRoute('/referrer/linkToSale/'),
  REFERRER_LINK_TO_SALE_P: apiRoute('/referrer/linkToSale/:referrerCode'),
  REFERRER_LINK_TO_INTERACTION: apiRoute('/referrer/linkInteraction'),
  REFER_CONTACT: apiRoute('/referrer/refer'),
  REFER_CONTACT_P: apiRoute('/referrer/refer:type'),
  BROKER_LINK_TO_INTERACTION: apiRoute('/broker/linkInteraction'),
  GET_BROKER_QA_CONFIG: apiRoute('/broker-qa-config'),
  FETCH_IEMAS_MEMBER: apiRoute('/broker/fetchIemasMember'),
  CHECK_IF_REFERRER_EXISTS: apiRoute('/referrer/get-status'),
  GET_REFERRER_INFO: apiRoute('/referrer/get-referrer-details'),
  REPLACE_AND_SAVE_ADD_INSURED_COVER_DETAILS: apiRoute('/replace-and-save/add-insured-cover-details'),
  REPLACE_AND_SAVE_LIST_REPLACEMENT_POLICIES: apiRoute('/replace-and-save/list-replacement-policies'),
  REPLACE_AND_SAVE_LIST_CANCELLATION_LETTERS: apiRoute('/replace-and-save/list-cancellation-letters'),
  REPLACE_AND_SAVE_ADD_REPLACEMENT_POLICY: apiRoute('/replace-and-save/add-replacement-policy'),
  REPLACE_AND_SAVE_REMOVE_REPLACEMENT_POLICY: apiRoute('/replace-and-save/remove-replacement-policy'),
  REPLACE_AND_SAVE_FETCH_FAMILY_MEMBERS: apiRoute('/replace-and-save/list-family-members'),
  REPLACE_AND_SAVE_ALLOCATE_COVER: apiRoute('/replace-and-save/allocate-cover'),
  REPLACE_AND_SAVE_REPLACEMENT_POLICIES_SAVINGS_INFORMATION:
    apiRoute('/replace-and-save/savings-information'),
  REPLACE_AND_SAVE_POPULATE_EXTENDED_FAMILY_FUNERAL_MEMBERS:
    apiRoute('/replace-and-save/populate-extended-family-funeral-members'),
  REPLACE_AND_SAVE_DOWNLOAD_CANCELLATION_LETTER:
    apiRoute('/replace-and-save/download-cancellation-letter'),

  HOTLEAD: apiRoute('/hotlead'),
  PRICING: apiRoute('/quote/:packageId'),
  DETAILS_CAPTURE_UNDERWRITING: '/details-capture/underwriting',
  DETAILS_CAPTURE_PEEPS: '/details-capture/peeps',
  GET_INTERACTION: apiRoute('/rehydration/get-interaction'),
  GET_REHYDRATION_URL: apiRoute('/rehydration/get-url'),
  SEND_REHYDRATION_NOTIFICATION: apiRoute('/rehydration/send-notification'),
  LOG_EVENT: apiRoute('/events/log'),
  SALE_REQUIREMENTS_LINK_TO_INTERACTION: apiRoute('/link-sale-requirements'),
  ACTIVE_USERS: apiRoute('/analytics/active-users'),
  PRICE_EXTENDED_FAMILY_MEMBER: apiRoute('/quote/price-extended-family-member'),
  SAVE_EXTENDED_FAMILY_PEEPS: apiRoute('/quote/save-extended-family-peeps'),
  SELECT_EXTENDED_FAMILY_FUNERAL: apiRoute('/select-extended-family'),
  GET_NEW_EXTENDED_FAMILY_PRICING: apiRoute('/reprice-extended-family-members'),
  SAVE_LAST_LOCATION: apiRoute('/save-last-known-route'),
  GET_LAST_LOCATION: apiRoute('/get-last-known-route'),
  PERSAL_MANDATE_UPLOAD: apiRoute('/persal/mandate-upload'),
  PERSAL_MANDATE_DOWNLOAD: apiRoute('/persal/mandate-download'),
  PERSAL_CHECK_AFFORDABILITY: apiRoute('/persal/check-affordability'),
  PERSAL_REQUEST_ELECTRONIC_MANDATE: apiRoute('persal/request-electronic-mandate'),
  PERSAL_CHECK_MANDATE_UPLOADED: apiRoute('persal/check-mandate-uploaded'),
  SALARY_DEDUCTION_RESERVE_TRANSACTION_NUMBER: apiRoute('/salary-deduction/reserve-transaction-number'),
  SALARY_DEDUCTION_DOWNLOAD_MANDATE: apiRoute('/salary-deduction/mandate'),
  COMPLIANCE_DOC_UPLOAD: apiRoute('/broker/compliance-doc-upload'),
};

API_ROUTES.toReferrerLinkToSale = (referrerCode) =>
  `${API_ROUTES.REFERRER_LINK_TO_SALE}${referrerCode}`;

export const HOME_CONSTANTS = {
  GET_PACKAGE_TEXT: 'Buy Now',
  MORE_INFO: 'Find out more',
};

export const FOOTER_CONSTANTS = {
  fspNumber: 47146,
};

export const COMPANY_DETAILS = {
  CALLCENTER_NUMBER: '021 045 1393',
  IUA_TELEPHONE: '021 045 1513',
  EMAIL: 'queries@simply.co.za',
  CLAIMS_EMAIL: 'claims@simply.co.za',
  OMART_EMAIL: 'omartadm@oldmutual.com',
  COMPLAINTS_EMAIL: 'complaints@simply.co.za',
  COMPLIANCE_EMAIL: 'compliance@simply.co.za',
  COMPLIANCE_OFFICER_NAME: 'Stephen Vivien',
  COMPLIANCE_OFFICER_FIRSTNAME: 'Stephen',
  COMPLIANCE_OFFICER_FIRM: 'Lombard Insurance',
  ADDRESS_1: '3rd Floor, Grove Exchange',
  ADDRESS_2: '9 Grove Ave, Claremont, Cape Town,',
  ADDRESS_3: '7708',
  REGISTRATION_NUMBER: '2011/132479/07',
  DIRECTOR_NAME: 'A. Miller',
};

/* eslint-disable */

export const SEO_CONTENT = {
  PACKAGES:
    'Get up to R1,500,000 of Life and Disability Cover and up to R50 000 of Family Funeral Cover in one easy package.',
};

export const COMPLIANCE_MESSAGES = {
  CELLPHONE_NUMBER_MESSAGE: `By providing us with this number, you authorise Simply to contact you via SMS or phone to help you with this application
    or to tell you about other products. You can opt out of being contacted at any time.`,
};

/* eslint-enable */

export const DEFAULT_THEME = 'simply';

export const DEFAULT_SENDER_REFERENCE = 'simply';

export const EXTERNAL_ROUTES = {
  TERMS_AND_CONDITIONS: 'terms-conditions',
};

export const GLOBAL_INCEPTION_MONTH_DAY = 10;

export const PERSAL_STAGE_START = 'start';
export const PERSAL_STAGE_BUSY = 'busy';
export const PERSAL_STAGE_QA = 'qa';
export const PERSAL_STAGE_FINISH = 'finish';
export const PERSAL_STAGE_AFFORDABLE = 'affordable';
export const PERSAL_STAGE_INVALID_EMPLOYEE = 'invalid-employee';
export const PERSAL_STAGE_NOT_AFFORDABLE = 'not-affordable';
export const PERSAL_STAGE_POLLING = 'polling';
export const PERSAL_STAGE_UNKNOWN = 'unknown';
