import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox } from '@simply-fin-services/astronomix3';
import {
  submitPeeps,
  toggleHasNoSpouseOrChildrenUnder21,
} from '../../actions/detailsCapture';
import PageActionButtons from '../common/PageActionButtons';
import Beneficiaries from '../peeps/Beneficiaries';
import Insureds from '../peeps/Insureds';
import { getSelectedPeeps } from '../peeps/PeepsPage';
import {PageDescriptionHtml} from '../common/PageDescription';
import getSelectedProductIds from '../peeps/getSelectedProductIds';
import { merge, assoc } from 'ramda';
import { isBrokerSale, isFromBrokerPortal } from '../../common/brokers';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { fromJS } from 'immutable';
const insuredsDescription =
  'Your policy includes family funeral cover, which covers you, your spouse and your legal children under 21. Please provide their details.'; // eslint-disable-line max-len
const beneficiariesDescription = ''; // eslint-disable-line max-len
class DetailPeeps extends PureComponent {
  render() {
    const {
      next,
      hasNoSpouseOrChildrenUnder21,
      peepsProvided,
      beneficiariesProvided,
      productSelectionRequirements,
      isThirdPartyBrokerSale,
      policyCessionFormIsValid,
      hasCession
    } = this.props;
    const meetsGoNextCriteria =
      peepsProvided ||
      (beneficiariesProvided && hasNoSpouseOrChildrenUnder21) ||
      productSelectionRequirements.canGoNext;
    const canGoNext = hasCession ? meetsGoNextCriteria && policyCessionFormIsValid : meetsGoNextCriteria;
    const pageActions = () => (
      <PageActionButtons
        text="Next"
        onClick={next}
        disabled={!canGoNext}
        canGoBack
      />
    );
    const description = isThirdPartyBrokerSale
      ? 'Next, please provide the details of beneficiaries, or any lives covered under the Family Funeral benefit'
      : 'Next, please provide the details of your loved ones <br /> in case there’s a claim.';
    return (
      <React.Fragment>
        <PageDescriptionHtml description={description} />
        <div className="row">
          <div className="col-xs-12">
            <Beneficiaries description={beneficiariesDescription} />
            <Insureds description={insuredsDescription}>
              {!peepsProvided && (
                <Checkbox
                  labelText={"I don't have a spouse or children under 21"}
                  id="no-spouse-or-children-checkbox"
                  onChange={() =>
                    this.props.toggleHasNoSpouseOrChildrenUnder21(
                      !hasNoSpouseOrChildrenUnder21
                    )
                  }
                  defaultChecked={hasNoSpouseOrChildrenUnder21}
                />
              )}
            </Insureds>
            {pageActions()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DetailPeeps.propTypes = {
  next: PropTypes.func.isRequired,
  hasNoSpouseOrChildrenUnder21: PropTypes.bool.isRequired,
  toggleHasNoSpouseOrChildrenUnder21: PropTypes.func.isRequired,
  peepsProvided: PropTypes.bool.isRequired,
};

const peepsProvided = (peeps, state) => {
  if (!peeps) {
    return false;
  }
  const selectedProductIds = getSelectedProductIds(state);
  const collectionHasPeepsBySelectedProdudcts = (peepsCollection) =>
    selectedProductIds.reduce((hasAtLeastOne, productId) => {
      const productPeeps = peepsCollection[productId];
      return (
        hasAtLeastOne || (productPeeps !== undefined && productPeeps.length > 0)
      );
    }, false);

  return collectionHasPeepsBySelectedProdudcts(peeps);
};

const productSelectionRequirements = ({ beneficiaries }, state) => {
  const selectedProductIds = getSelectedProductIds(state);
  const peepsProcess = selectedProductIds.reduce(
    (requirements, productId) => {
      const productBeneficiaries = beneficiaries
        ? beneficiaries[productId]
        : undefined;
      const isLife =
        state.getIn(['products', productId, 'isLifeProduct']) || false;
      const isDisability =
        state.getIn(['products', productId, 'isDisabilityProduct']) || false;
      const isFuneral =
        state.getIn(['products', productId, 'isFuneralProduct']) || false;
      const productHasBeneficiaries =
        productBeneficiaries !== undefined && productBeneficiaries.length > 0;

      return merge(requirements, {
        hasLifeBenefit: requirements.hasLifeBenefit || isLife,
        hasDisabilityBenefit: requirements.hasDisabilityBenefit || isDisability,
        hasFuneralbenefit: requirements.hasFuneralbenefit || isFuneral,

        hasLifeBeneficiaries:
          (isLife && productHasBeneficiaries) ||
          requirements.hasLifeBeneficiaries,
        hasFuneralInsureds:
          (isLife && productHasBeneficiaries) ||
          requirements.hasLifeBeneficiaries,
        hasFuneralBeneficiaries:
          requirements.hasFuneralBeneficiaries ||
          (isFuneral && productHasBeneficiaries),
      });
    },
    {
      hasLifeBenefit: false,
      hasDisabilityBenefit: false,
      hasFuneralbenefit: false,
      hasLifeBeneficiaries: false,
      hasfuneralInsureds: false,
      hasFuneralBeneficiaries: false,
    }
  );
  const mayContinueWithLifeOnly =
    peepsProcess.hasLifeBenefit && peepsProcess.hasLifeBeneficiaries;
  const mayContinueWithDisabilityOnly =
    peepsProcess.hasDisabilityBenefit &&
    !peepsProcess.hasLifeBenefit &&
    !peepsProcess.hasFuneralBenefit;
  const hasLifeAndDisability =
    peepsProcess.hasLifeBenefit && peepsProcess.hasDisabilityBenefit;
  const mayContinueWithLifeAndDisability =
    hasLifeAndDisability && peepsProcess.hasLifeBeneficiaries;
  const mayContinueWithFuneralOnly =
    peepsProcess.hasFuneralbenefit &&
    peepsProcess.hasFuneralBeneficiaries &&
    !peepsProcess.hasLifeBenefit &&
    !peepsProcess.hasDisabilityBenefit;

  const packageSlug= state.get('selectedPackageSlug');
  const packageAllowsEffWithoutFuneral = state.getIn([
    'packages',
    packageSlug,
    'supportsExtendedFamilyWithoutFuneralCover',
  ]);
  const mayContinueWithEffOnly = peepsProcess.hasEffBenefit && packageAllowsEffWithoutFuneral;
  const canGoNext =
    mayContinueWithLifeOnly ||
    mayContinueWithDisabilityOnly ||
    mayContinueWithLifeAndDisability ||
    mayContinueWithFuneralOnly ||
    mayContinueWithEffOnly;
  return assoc('canGoNext', canGoNext, peepsProcess);
};

export const mapStateToProps = (state) => {
  const selectedPeeps = getSelectedPeeps(state);
  const beneficiariesProvided = peepsProvided(
    selectedPeeps.beneficiaries,
    state
  );
  const packageSlug = state.get('selectedPackageSlug');
  const isThirdPartyPackage = state.getIn([
    'packages',
    packageSlug,
    'isThirdPartyPolicy',
  ]);
  const isBrokerOperatedSale = isBrokerSale(state) || isFromBrokerPortal(state);

  const insuredsProvided = peepsProvided(selectedPeeps.insureds, state);
  const cessionQAQuestions = state.getIn([
    'questionPages',
    'cession-questionnaire',
    'questions'
  ]);
  const optionalPageQuestions = fromJS([]);
  const { isValid } = stateToQuestionnaireProps(
    state,
    cessionQAQuestions,
    optionalPageQuestions
  );
  const hasCession = state.getIn(['answers', 'has-cession', 'answer']) === "Yes";
  return {
    productSelectionRequirements: productSelectionRequirements(
      selectedPeeps,
      state
    ),
    selectedPeeps,
    hasNoSpouseOrChildrenUnder21: state.getIn([
      'peeps',
      'hasNoSpouseOrChildrenUnder21',
    ]),
    peepsProvided: insuredsProvided && beneficiariesProvided,
    beneficiariesProvided,
    isThirdPartyBrokerSale: isThirdPartyPackage && isBrokerOperatedSale,
    policyCessionFormIsValid: isValid,
    hasCession,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  next: (peeps) => () => dispatch(submitPeeps(peeps.insureds, peeps.beneficiaries)),
  toggleHasNoSpouseOrChildrenUnder21: (hasNoSpouseOrChildrenUnder21) => dispatch(
      toggleHasNoSpouseOrChildrenUnder21(hasNoSpouseOrChildrenUnder21)
  ),
});

const mergeProps = (stateProps, dispatchProps) =>
  Object.assign({}, stateProps, dispatchProps, {
    next: dispatchProps.next(stateProps.selectedPeeps),
  });

const DetailPeepsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DetailPeeps);

export default DetailPeepsContainer;
