import React, { PureComponent } from 'react';
import { ROUTES } from '../../config/constants';
import { Link } from 'react-router-dom';
import PageHeader from '../common/PageHeader';

export default class TermsAndConditions extends PureComponent {
  render() {
    return (
      <div>
        <PageHeader heading="Referral Ts&Cs" />
        <div className="row">
          <div className="col-xs-12">
            <ol>
              <p className="text-justify">
                <strong>Participation</strong>
                <br />
              </p>
              <li>
                By participating in the Simply Referral Program you are entering
                into an agreement with Simply Financial Services (“Simply”)
                only. Simply Financial Services Pty Ltd is a registered
                Financial Services Provider. We market, distribute and service
                long-term insurance products.
              </li>
              <li>
                The Simply Referral Program is a program open to anyone over the
                age of 18 years, who wishes to participate in the opportunity to
                earn referral fees.
              </li>
              <li>
                There is no need to have a Simply insurance policy underwritten
                by Old Mutual Alternative Risk Transfer Limited (OMART) to
                participate in the program. OMART is a registered Long-term
                insurer.
              </li>
              <li>
                When you sign up for the Simply Referral Program you will be
                provided with a unique ID. This ID will be used to identify you
                as the referrer when customers you have referred buy a Simply
                insurance policy.
              </li>
              <p className="text-justify">
                <br />
                <strong>Providing referrals</strong>
                <br />
              </p>
              <li>
                You may be eligible to earn a referral fee in return for
                providing Simply with the contact details of persons who may be
                interested in buying a Simply insurance policy.
              </li>
              <li>
                You may provide such contact details by completing a referral
                form on the Simply website (simply.co.za). When you provide such
                contact details, you must provide your unique ID so that Simply
                can allocate your referral fee to you should it become due.
              </li>
              <li>
                Before providing us with the contact details of any person
                please make sure they have agreed to allow you to provide their
                details to us for the purpose of us contacting them about our
                products and services. You indemnify us against any claims from
                any person alleging that they did not consent to you sharing
                their contact details with us.
              </li>
              <li>
                Where you have shared contact details with us, we will provide
                your name on request to any person enquiring where we obtained
                their contact details.
              </li>
              <li>
                Where Simply receives the contact details for the same person,
                the Simply referrer that provided the details first in time may
                be eligible for the referral fee provided the terms and
                conditions of the Simply Referral Program are complied with.
              </li>
              <li>
                You may also be eligible to earn a referral fee if you direct
                persons who may be interested in buying a Simply insurance
                policy to the Simply website or the Simply call centre.
              </li>
              <li>
                If you direct such persons to the Simply website or the Simply
                call centre, you must provide them with your unique ID so they
                can tag you as the referrer when they sign up for a Simply
                insurance policy. If this unique ID is not provided, you may not
                be eligible for the referral fee.
              </li>
              <li>
                You will not receive a referral fee for referring yourself.
              </li>
              <p className="text-justify">
                <br />
                <strong>Referral Fee</strong>
                <br />
              </p>
              <li>
                The referral fee, if due, will be payable to you only once ONE
                premium payment have been collected from the referred customer.
              </li>
              <li>
                The amount of the fee to be paid will be EQUAL to the monthly
                premium payment paid by the referred customer.
              </li>
              <li>The referral fee is not transferable.</li>
              <p className="text-justify">
                <br />
                <strong>Restrictions</strong>
                <br />
              </p>
              <li>
                You are not permitted to sell, provide advice or render
                intermediary services as defined under the Financial Advisory
                and Intermediary Services Act, 2002 (FAIS Act), including but
                not limited to:
                <ul>
                  <li>
                    Giving any financial recommendation, guidance or proposals
                    on Simply insurance policies;
                  </li>
                  <li>
                    Acting in a way that encourages a person to enter into a
                    Simply insurance policy;
                  </li>
                  <li>Selling Simply insurance policies;</li>
                  <li>
                    Collecting any fees or premiums relating to Simply insurance
                    policies; and
                  </li>
                  <li>Dealing with any claims of a customer.</li>
                </ul>
              </li>
              <p className="text-justify">
                <br />
                <strong>Protection of personal information</strong>
                <br />
              </p>
              <li>
                Simply respects the privacy of individuals and all data
                collected and processed will be done in accordance with SA data
                protection legislation currently in force.
              </li>
              <li>
                We may use your personal information for the purpose of this
                Simply Referral Program, including statistical research purposes
                and for the payment of fees.
              </li>
              <li>
                We may disclose your personal information to our employees and
                third party service providers who assist us to interact with
                you. We may be required to disclose your personal information if
                required to comply with applicable law or an order of court. We
                will never sell your personal information.
              </li>
              <li>
                Simply may hold your personal information in either electronic
                or hard copy form. In both cases, Simply will take appropriate
                and reasonable steps to ensure that your personal information is
                protected from misuse and loss and from unauthorised access,
                modification or disclosure. Simply cannot however guarantee
                that, as a result of these measures, your personal information
                will not be disclosed in an unauthorised or unlawful manner in
                circumstances, which are beyond our control.
              </li>
              <li>
                If you want confirmation that Simply holds your information,
                access to your personal information, the identities or
                categories of third parties to whom Simply has disclosed your
                personal information, or to amend, delete or update your
                personal information, please make your request in writing to
                queries@simply.co.za. Any personal information Simply does not
                require to perform under this mandate, or when Simply is no
                longer required by law to retain it (whichever is the later),
                will be destroyed or anonymised.
              </li>
              <p className="text-justify">
                <br />
                <strong>Changes to the terms and conditions</strong>
                <br />
              </p>
              <li>
                The Simply Referral Program’s terms and conditions, including
                the fees, may be changed by Simply at any time without notice to
                you.
              </li>
              <li>
                If the Simply Referral Program’s terms and conditions, including
                the fees, are changed, then the new terms and conditions will
                apply to all new referrals provided by you.
              </li>
              <p className="text-justify">
                <br />
                <strong>Termination</strong>
                <br />
              </p>
              <li>
                You may terminate your participation in the Simply Referral
                Program at any time by sending an email to queries@simply.co.za.
              </li>
              <li>
                Simply may terminate the Simply Referral Program, or your
                participation in the Simply Referral Program, at any time.
              </li>
              <li>
                In the event that the Simply Referral Program, or your
                participation in the Simply Referral Program, is terminated and
                you are not in breach of the terms and conditions of the Simply
                Referral Program, then You will retain the right to fees due on
                business referred by you up until that point.
              </li>
              <p className="text-justify">
                <br />
                <strong>Other</strong>
                <br />
              </p>
              <li>
                Simply or Old Mutual Alternative Risk Transfer Limited will not
                be liable to you or any third party for any loss or damages
                arising from the Simply Referral Program.
              </li>
              <li>
                Any disputes or questions regarding this mandate will be
                resolved by Simply in its sole discretion.
              </li>
              <li>
                Nothing in the Simply Referral Program will create any
                partnership, joint venture, agency representative or employment
                relationship between you and Simply or will mean you are an
                intermediary as contemplated in the FAIS Act.
              </li>
              <li>
                If any of these terms are held to be invalid, void, or
                unenforceable, such provision (or the part of it that makes it
                invalid, void or unenforceable) will be struck and not affect
                the validity of an enforceability of the remaining provisions.
              </li>
              <li>
                The terms and conditions of this Simply Referral Program will be
                governed by the laws of South Africa.
              </li>
              <p className="text-justify">
                <br />
                <strong>Acceptance</strong>
                <br />
              </p>
              <li>
                By checking the box or clicking the “Sign up” button you agree
                to the terms and conditions to the Simply Referral Program.
              </li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 text-center">
            <Link
              className="btn btn-primary action"
              to={ROUTES.REFERRAL_SIGNUP}
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
