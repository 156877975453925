import React, { Fragment } from 'react';
import {
  InputFile,
  PrimaryButton,
  SecondaryButton,
  GhostButton,
  Buttons,
  WallOfTextContainer,
} from '@simply-fin-services/astronomix3';
import PageActionButtons from '../common/PageActionButtons';
import { getSelectedPackageIdOrDefault } from '../../common/getPackageIdFromSlug';
import { fromJS } from 'immutable';
import { ROUTES,
  PERSAL_STAGE_START,
  PERSAL_STAGE_BUSY,
  PERSAL_STAGE_QA,
  PERSAL_STAGE_FINISH,
  PERSAL_STAGE_AFFORDABLE,
  PERSAL_STAGE_INVALID_EMPLOYEE,
  PERSAL_STAGE_NOT_AFFORDABLE,
} from '../../config/constants';
import {
  checkAffordability as checkAffordabilityActionCreator,
  downloadMandate as downloadMandateActionCreator,
  uploadMandate,
  restart as restartActionCreator,
  selectMandateType as selectMandateTypeActionCreator,
  requestElectronicMandate as requestElectronicMandateActionCreator,
  startConfirmMandateSigned as startConfirmMandateSignedActionCreator,
  toggleVoiceMandateTerm as toggleVoiceMandateTermAction,
} from '../../actions/persal';
import { connect } from 'react-redux';
import { stateToQuestionnaireProps } from '../questionnaire/toQuestionPageProps';
import { QuestionPageQuestions } from '../questionnaire/QuestionPage';
import { answerQuestion as answerQuestionActionCreator } from '../../actions/answers';
import { curry, values as Rvalues } from 'ramda';
import Spinner from '../common/Spinner';
import { toPremium } from '../common/numberFormats';
import OneTimePin, { isOtpValid } from './Otp';
import { push } from 'connected-react-router';
import config, { getFinishButtonText } from './config';
import BrokerQa from './brokerQa';
import BrokerCompliance from './brokerCompliance';
import AcceptCheckboxQuestion from '../questions/AcceptCheckboxQuestion';
import { getPersalMandateType } from '../../common/saleOptions';
import moment from 'moment';
import { MANDATE_TYPE_VOICE } from '../../.shared/paymentDetails/persalSalaryDeduction';
import {isReplaceAndSaveEnabled} from '../../stateStore/replaceAndSave';
import {inceptionDate as persalInceptionDate,
        firstSalaryDeductionCollectionMonth as persalFirstSalaryDeductionCollectionMonth}
        from '../../stateStore/persal';
import PersalInceptionOptionsContainer from '../../views/replaceAndSave/integration/PersalInceptionOptionsContainer';

function PaperMandate(props) {
  const { mandateResult, downloadMandate, postUploadMandate } = props;

  return (
    <div>
      <p>
        Please sign and upload the mandate document and ensure the above details
        are correctly captured
      </p>
      <Buttons>
        <PrimaryButton
          style={{ marginRight: '0.5rem' }}
          onClick={downloadMandate}
        >
          Download Mandate
        </PrimaryButton>

        <InputFile
          id="mandate-file"
          buttonConfig={{ colour: 'primary' }}
          inputConfig={{
            onChange: (e) => postUploadMandate(e.target.files[0]),
          }}
        >
          Upload mandate
        </InputFile>
      </Buttons>
      {mandateResult && (
        <small>
          <strong>{mandateResult.originalFileName} uploaded!</strong>
        </small>
      )}

      <p>
        <small>
          You can download a blank mandate form{' '}
          <a
            href="https://storage.googleapis.com/simply-public/persal/PaperMandate_V5.pdf"
            target="blank"
          >
            here
          </a>
          . However we <strong>strongly suggest</strong> you{' '}
          <a onClick={downloadMandate}>download the completed mandated</a>{' '}
          instead as all fields will be filled in correctly.
        </small>
      </p>
    </div>
  );
}

function ElectronicMandate(props) {
  const { electronicMandate } = props;
  if (electronicMandate) {
    const isUploaded = electronicMandate.get('isUploaded');
    return (
      <div>
        <p>
          <br />
          <a
            href={electronicMandate.get('url')}
            target="_blank"
            rel="noreferrer"
          >
            Click here{' '}
          </a>
          to sign the mandate on this device.
          <br />
          Alternatively scan the QR code and sign on your mobile device.
          <br />
          Click the 'Signature Completed' button once the document has been
          signed
        </p>
        <figure>
          <img
            alt={electronicMandate.get('url')}
            src={`data:image/png;base64, ${electronicMandate.get('qr')}`}
          />
        </figure>
        {isUploaded !== true && (
          <Buttons>
            <PrimaryButton
              onClick={() => props.confirmElectronicMandateSigned()}
              disabled={isUploaded === 'checking'}
            >
              Signature Completed
            </PrimaryButton>
          </Buttons>
        )}
        {isUploaded === true && (
          <p>
            Mandate successfully signed. The customer will receive an sms with a
            download link.
          </p>
        )}
      </div>
    );
  }
  return <Spinner width="25px" />;
}

function MandateTypeSelection(props) {
  const { affordabilityResult, stage } = props;

  if (!affordabilityResult && stage === PERSAL_STAGE_QA) {
    return null;
  }

  const mandateTypes = {
    paper: {
      type: 'paper',
      heading: 'Print and Sign',
      component: PaperMandate,
    },
    electronic: {
      type: 'electronic',
      heading: 'Sign Online',
      component: ElectronicMandate,
      init: props.requestElectronicMandate,
    },
  };
  const selectedType = props.paymentDetails.get('mandateType');
  const Comp =
    mandateTypes[selectedType] && mandateTypes[selectedType].component;
  const isTypeSelected = selectedType !== 'not-selected';
  return (
    <div className="text-center">
      <div style={{ textAlign: 'justify' }}>
        <p>I the undersigned:</p>
        <ul>
          <li>
            <strong>Full name: </strong>
            {affordabilityResult.employeeFullName}
          </li>
          <li>
            <strong>Salary No: </strong>
            {affordabilityResult.employeeNumber}
          </li>
          <li>
            <strong>Identity No: </strong>
            {affordabilityResult.employeeIdentityNumber}
          </li>
          <li>
            <strong>Reference No: </strong>
            {affordabilityResult.transactionNumber}
          </li>
        </ul>
        <p>
          hereby authorise the Accountant of the Department/Administration of{' '}
          <b>{affordabilityResult.employeeDepartment} </b>
          to deduct monthly with effect from{' '}
          <b>{props.firstSalaryDeductionCollectionMonth} </b>
          the premium of{' '}
          <b>{toPremium(affordabilityResult.premiumInCents / 100)} </b>
          from my salary and to remit it to Old Mutual Alternative Risk Transfer
          until such time as I cancel this authorization in writing, or until I
          substitute it with a new authorization.
          <br />
          <br />
          Should the relevant premium rate be adjusted by the institution as a
          result of a general decrease/increase in subscription or should I
          request the institution to decrease/increase the subscription for
          certain reasons, I confirm that the adjusted premium may be deducted
          from my salary, until such time as I cancel this authorization in
          writing or until I substitute it with a new authorization.
        </p>
      </div>

      <Buttons>
        {Rvalues(mandateTypes).map((t) => {
          const B = t.type === selectedType ? PrimaryButton : GhostButton;
          const onClick = () => {
            props.selectMandateType(t.type);
            if (t.init) {
              t.init();
            }
          };
          return (
            <B key={t.type} onClick={() => onClick()} disabled={isTypeSelected}>
              {t.heading}
            </B>
          );
        })}
      </Buttons>
      {Comp && <Comp {...props} />}
    </div>
  );
}

function VoiceMandateType(props) {
  const { affordabilityResult, voiceMandateTermAccepted, onChange } = props;
  const today = moment().format('Do of MMMM YYYY');
  const date = moment(affordabilityResult.salaryMonth,'YYYY/MM');
  const salaryMonth = date.format('MMMM YYYY');

  return (
    <div className="text-center">
      <div style={{ textAlign: 'justify' }}>
        <p>We now need to collect details for the payment of the premium. We do have a special option for government employees.</p>
        <ol>
          <li>
            Do you work for the government?
          </li>
          <li>
            Are you paid through the government payroll?
          </li>
          <li>
            Do you know your employee number?<br /><br />
            (If NO to any of these, move on to standard debit order)<br /><br />
            You have the option of paying your premium through a deduction from your salary if you would prefer this. By doing this, there is less risk that the premium cannot be collected and your cover lapses.<br /><br />
          </li>
          <li>
            Would you like to pay your premium through a payroll deduction from your salary?<br /><br />
            (If NO to this, move on to standard debit order)<br /><br />
            There are a few questions I need to ask, and some disclosures that I need to make in order for the premium to be collected in this way, I might need to repeat some of the information you’ve already given me, or that I have already given you. This will take another 5 minutes or so.<br /><br />
          </li>
          <li>
            This policy is underwritten by Old Mutual Alternative Risk Transfer Limited, they are the insurer of this policy.
          </li>
          <li>
            Simply Financial Services is the name of the company that is selling this policy to you and obtaining this authorisation from you. We are an authorised financial services provider.
          </li>
          <li>
            Can you confirm which government department you work for?
            <ol type="a">
              <li>If answer is Department of Defence – What unit do you work for in the Department of Defence?</li>
            </ol>
          </li>
          <li>
            What is your employee number?
          </li>
          <li>
            What is your surname?
          </li>
          <li>
            What are your initials?
          </li>
          <li>
            What is your ID number?
          </li>
          <li>
            I just need to confirm the following information about this conversation for the record:
            <ol type="a">
              <li>Today is the <b>{today}</b>. </li>
              <li>The first deduction from your salary will be in <b>{salaryMonth}</b>.</li>
              <li>The premium that will be deducted will be{' '}
                <b>{toPremium(affordabilityResult.premiumInCents / 100)}</b>. This amount will increase by 5% on every policy anniversary.
              </li>
            </ol>
          </li>
          <li>
            I now need you to confirm that you authorise Old Mutual Alternative Risk Transfer Limited to deduct the premium due for this Simply policy from your salary as from <b>{salaryMonth}</b>.

          </li>
          <br />
          <AcceptCheckboxQuestion
            onChange={onChange}
            checked={voiceMandateTermAccepted}
            id="final"
          >
            <div>
              Yes, I confirm.
            </div>
          </AcceptCheckboxQuestion>
        </ol>
        <p>Thank you very much, the authorisation is complete.</p>
      </div>
    </div>
  );
}

// Main component

class PersalSalaryDeduction extends React.Component {
  constructor() {
    super();
    this.toggleVoiceMandateTerm = this.toggleVoiceMandateTerm.bind(this);
  }

  toggleVoiceMandateTerm() {
    this.props.toggleVoiceMandateTerm({ isAccepted: !this.props.voiceMandateTermAccepted });
    this.props.selectMandateType(MANDATE_TYPE_VOICE);
  }

  // Shows the compliance and broker OTP completion process
  complianceAndCompletion() {
    const {getPersalMandate, requiresOTP, stage,} = this.props;
    const persalMandateType = getPersalMandate();

    return (
      <WallOfTextContainer>
        {stage !== PERSAL_STAGE_QA && persalMandateType === MANDATE_TYPE_VOICE && (
          <VoiceMandateType
            voiceMandateTermAccepted={this.props.voiceMandateTermAccepted}
            onChange={this.toggleVoiceMandateTerm}
            {...this.props}
          />
        )}

        {persalMandateType !== MANDATE_TYPE_VOICE && (
          <MandateTypeSelection {...this.props} />
        )}

        <BrokerCompliance type="persal" />

        {requiresOTP && stage === PERSAL_STAGE_FINISH && (
          <OneTimePin form="persal_salary_deduction" />
        )}
      </WallOfTextContainer>
    );
  }

  render() {
    const {
      isSaving,
      next,
      stage,
      canContinue,
      questionPageProps,
      affordabilityResult,
      checkAffordability,
      restart,
      adjustCover,
      error,
      toFinishButtonText,
      rejectSale,
      sendForCorrections,
      validComments,
      salePaymentDetails,
      onLoadAction,
    } = this.props;

    if (stage === PERSAL_STAGE_START) {
      onLoadAction();
    }

    const updateCorrectionComments = (value) => {
      this.props.updateCorrectionComments(value);
    };

    const actionButtonText = toFinishButtonText();

    return (
      <Fragment>
        {stage === PERSAL_STAGE_QA && !isSaving && (
          <Fragment>
            <WallOfTextContainer>
              <ul style={{ 'text-align': 'left' }}>
                <li>
                  <b>Mandate Type: </b>
                  {salePaymentDetails.get('mandateType')}
                </li>
                <li>
                  <b>Employee Number: </b>
                  {salePaymentDetails.get('employeeNumber')}
                </li>
                <li>
                  <b>Inception Month: </b>
                  {salePaymentDetails.get('inceptionMonth').substring(0, 7)}
                </li>
              </ul>
            </WallOfTextContainer>
            {this.complianceAndCompletion(this.props)}
            <BrokerQa
              updateCorrectionComments={updateCorrectionComments}
              canContinue={canContinue}
              rejectSale={rejectSale}
              validComments={validComments}
              approveSale={next}
              sendForCorrections={sendForCorrections}
            />
          </Fragment>
        )}
        <WallOfTextContainer>
          {stage === PERSAL_STAGE_BUSY && <div>Please wait...</div>}

          {stage === PERSAL_STAGE_START && (
            <div>
              <p>
                Please verify your <strong>Persal Employee Number</strong>. By
                clicking verify you authorise us to check affordability.
              </p>
              <QuestionPageQuestions {...questionPageProps} />
            </div>
          )}

          {stage === PERSAL_STAGE_INVALID_EMPLOYEE && (
            <div>
              <h5>Unfortunately persal salary deduction is not available</h5>
              <p>
                <strong>Employee Number: </strong>
                {affordabilityResult.employeeNumber}
                <br />
                <strong>Reason: </strong>
                {affordabilityResult.error}
              </p>
              <p>Please select debit order or <a onClick={restart}>start over</a>.</p>
            </div>
          )}

          {/* Default no affordability response which prevents sale completion */}
          {!this.props.isReplaceAndSaveEnabled && (stage === PERSAL_STAGE_NOT_AFFORDABLE) && (
            <div>
              <h5>Unfortunately this policy is deemed not affordable</h5>
              <p>
                <strong>Employee Number: </strong>
                {affordabilityResult.employeeNumber}
                <br />
                <strong>Reason: </strong>
                {affordabilityResult.error}
                <br />
                <br />
                Either <a onClick={adjustCover}> reduce the cover amounts</a>, <a onClick={restart}>start over</a> or select debit order.
              </p>
            </div>
          )}

          {/* Replace and save Persal section */}
          {this.props.isReplaceAndSaveEnabled && (stage === PERSAL_STAGE_AFFORDABLE || stage === PERSAL_STAGE_NOT_AFFORDABLE || stage === PERSAL_STAGE_FINISH)  && (
            <div>

              <PersalInceptionOptionsContainer
                inceptionOptions={affordabilityResult.inceptionOptions}
                isAffordable={stage === PERSAL_STAGE_AFFORDABLE}
              />

              <p><hr/></p>

              {this.complianceAndCompletion(this.props)}
            </div>)}

          {!this.props.isReplaceAndSaveEnabled && (stage === PERSAL_STAGE_AFFORDABLE ||
            stage === PERSAL_STAGE_FINISH) && (
            <div>
              <h5>Salary deduction allowed</h5>
              {this.complianceAndCompletion(this.props)}
            </div>
          )}

          {stage !== PERSAL_STAGE_QA &&
            (stage === PERSAL_STAGE_START || stage === PERSAL_STAGE_BUSY) && (
              <SecondaryButton
                onClick={checkAffordability}
                disabled={!questionPageProps.isValid}
                loading={stage === PERSAL_STAGE_BUSY}
              >
                Verify
              </SecondaryButton>
            )}

          {error && <p className="space-above text-danger">{error}</p>}

          {stage !== PERSAL_STAGE_QA && (
            <PageActionButtons
              onClick={next}
              disabled={!canContinue && !isSaving}
              loading={isSaving}
              text={actionButtonText}
              canGoBack
              errorText=" "
            />
          )}
        </WallOfTextContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const pageQuestions = state.getIn([
    'questionPages',
    'persal-payment-details',
    'questions',
  ]);
  const optionalPageQuestions = fromJS([]);
  const { walkedPage, isValid, questions, answers } = stateToQuestionnaireProps(
    state,
    pageQuestions,
    optionalPageQuestions
  );

  return {
    state,
    stage: state.getIn(['persal', 'stage']),
    affordabilityResult: state.getIn(['persal', 'affordability']),
    mandateResult: state.getIn(['persal', 'mandate']),
    electronicMandate: state.getIn(['persal', 'electronicMandate']),
    paymentDetails: state.getIn(['persal', 'paymentDetails']),
    salePaymentDetails: state.getIn(['bankingDetails', 'details']),
    toFinishButtonText: () => getFinishButtonText(state),
    questionPageProps: {
      walkedPage,
      isValid,
      questions,
      answers,
      pageQuestions,
      optionalPageQuestions,
    },
    packageId: getSelectedPackageIdOrDefault(state),
    packageUrlSlug: state.get('selectedPackageSlug'),
    otpValid: isOtpValid(state),
    error: state.getIn(['persal', 'error']),
    validComments: state.getIn(['brokerQa', 'isValidCorrectionComments']),
    brokerComplianceValid: state.getIn(['brokerCompliance', 'isValid']),
    voiceMandateTermAccepted: state.getIn(['persal', 'voiceMandateTermAccepted']),
    getPersalMandate: () => getPersalMandateType(state),
    isReplaceAndSaveEnabled: isReplaceAndSaveEnabled(state),
    inceptionDate: persalInceptionDate(state),
    firstSalaryDeductionCollectionMonth: persalFirstSalaryDeductionCollectionMonth(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkAffordability: () => dispatch(checkAffordabilityActionCreator()),
  postUploadMandate: (file) => dispatch(uploadMandate(file)),
  downloadMandate: () => dispatch(downloadMandateActionCreator()),
  restart: () => dispatch(restartActionCreator()),
  selectMandateType: (t) => dispatch(selectMandateTypeActionCreator(t)),
  requestElectronicMandate: () =>
    dispatch(requestElectronicMandateActionCreator()),
  confirmElectronicMandateSigned: () =>
    dispatch(startConfirmMandateSignedActionCreator()),
  answerQuestion: curry((packageId, question, answer) =>
    dispatch(answerQuestionActionCreator(packageId, question, answer))
  ),
  adjustCover: (packageUrlSlug) =>
    dispatch(push(`${ROUTES.CUSTOMIZE}${packageUrlSlug}`)),
  toggleVoiceMandateTerm: (isAccepted) =>
    dispatch(toggleVoiceMandateTermAction(isAccepted)),
  onLoadAction: () => dispatch(config.persal.onLoadAction()),
});

const canContinue = (stateProps, ownProps) => {
  if (ownProps.isSaving) {
    return false;
  }
  if (ownProps.requiresOTP && !stateProps.otpValid) {
    return false;
  }

  if(!stateProps.brokerComplianceValid) {
    return false;
  }
  return stateProps.stage === PERSAL_STAGE_FINISH || stateProps.stage === PERSAL_STAGE_QA;
};

const toSaveableBankingDetails = (stateProps) => {
  if (stateProps.stage === PERSAL_STAGE_QA) {
    return stateProps.salePaymentDetails;
  }
  return stateProps.paymentDetails;
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, stateProps, dispatchProps, ownProps, {
    questionPageProps: Object.assign(stateProps.questionPageProps, {
      answerQuestion: dispatchProps.answerQuestion(stateProps.packageId),
    }),
    next: () => ownProps.next(toSaveableBankingDetails(stateProps)),
    canContinue: canContinue(stateProps, ownProps),
    adjustCover: () => dispatchProps.adjustCover(stateProps.packageUrlSlug),
    onLoadAction: () => dispatchProps.onLoadAction(),
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PersalSalaryDeduction);