/* eslint-disable no-return-assign */
import React, { PureComponent, Fragment } from 'react';

import {
  PricingPopup,
  Modal,
  Header,
  PrimaryButton,
} from '@simply-fin-services/astronomix3';

import { connect } from 'react-redux';
import CallmeBackForm from '../callMeBack/CallMeBackForm';
import {
  saveCallMeBackDetails,
  closeCallmeBackMenu,
  openCallmeBackMenu,
} from '../../actions/callMeBack';
import { getPricingOverlayConfig } from './pricingOverlayConfig';
import { getPricingPopupConfig } from './pricingPopupConfig';
import { rehydrationComplete } from '../../actions/rehydration';
import { isFromBrokerPortal } from '../../common/brokers';
import * as R from 'ramda';
import { isMobile } from 'react-device-detect';

const exists = (i) => !R.isNil(i) && !R.isEmpty(i);

class NavBar extends PureComponent {
  constructor() {
    super();
    this.state = { routeIsNotBlocked: false, currentPage: '' };
  }

  componentWillMount() {
    this.setState({ currentPage: this.props.currentPage });
  }

  componentWillReceiveProps(nextProps) {
    const lastPage = exists(nextProps.rehydratedLastLocation)
      ? exists(nextProps.rehydratedLastLocation.location)
        ? nextProps.rehydratedLastLocation.location.location.pathname
        : null
      : null;
    if (lastPage === nextProps.currentPage) {
      const pricingPopupConfig = getPricingPopupConfig(
        nextProps,
        nextProps.state
      );
      const { routeIsNotBlocked } = pricingPopupConfig || {
        routeIsNotBlocked: false,
      };
      if (!routeIsNotBlocked) {
        this.props.dispatch(rehydrationComplete());
      }
      this.setState({ routeIsNotBlocked, currentPage: nextProps.currentPage });
    }
  }

  render() {
    const {
      WEB_SITE_BASE,
      SUFFIX_URL,
      TENANDSIX_URL,
      BROKER_PORTAL_URL,
      isSavingCallMeBack,
      saveCallmeBackDetails,
      menuOpen,
      state,
      dispatch,
      rehydrationLink,
      linksQuery,
    } = this.props;
    const pricingOverlayConfig = !isMobile
      ? getPricingOverlayConfig(this.props, state)
      : undefined;
    const adminDomain =
      R.includes('http://', SUFFIX_URL) || R.includes('https://', SUFFIX_URL)
        ? SUFFIX_URL
        : `https://${SUFFIX_URL}`;
    const groupDomain =
      R.includes('http://', TENANDSIX_URL) || R.includes('https://', TENANDSIX_URL)
        ? TENANDSIX_URL
        : `https://${TENANDSIX_URL}`;
    const callMeButton = [
      <PrimaryButton onClick={() => this.props.openCallmeBackMenu()}>
        Let us call you
      </PrimaryButton>,
    ];
    const pricingPopupConfig = getPricingPopupConfig(this.props, state);
    const { isRehydrating } = this.props.rehydratedLastLocation || {
      isRehydrating: false,
    };
    const { routeIsNotBlocked } = this.state;
    const isBroker = isFromBrokerPortal(state);
    const showPricingPopup = isRehydrating && routeIsNotBlocked && !isBroker;
    const ribbon = isBroker ? (
      <React.Fragment>
        <a href={BROKER_PORTAL_URL}>Go back to the Broker Portal</a>
      </React.Fragment>
    ) : null;

    return (
      <Fragment>
        {typeof window !== 'undefined' && (
          <Header
            adminDomain={adminDomain}
            groupDomain={groupDomain}
            linksQuery={linksQuery}
            websiteDomain={WEB_SITE_BASE}
            logoDomain={WEB_SITE_BASE}
            rightMenu={callMeButton}
            pricingOverlayConfig={pricingOverlayConfig}
            ribbon={ribbon}
            login={{ url: `${adminDomain}` }}
            rehydrationLink={rehydrationLink}
            topPaddingBuffer="40px"
            hideOurStory
          />
        )}

        <Modal show={menuOpen} handleClose={this.props.closeCallmeBackMenu}>
          <CallmeBackForm
            saveDetails={saveCallmeBackDetails}
            isSaving={isSavingCallMeBack}
          />
        </Modal>
        {!R.isNil(pricingPopupConfig) && (
          <PricingPopup
            show={showPricingPopup}
            title="Simply"
            config={pricingPopupConfig}
            handleClose={() => {
              dispatch(rehydrationComplete());
            }}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  WEB_SITE_BASE: state.getIn(['config', 'WEB_SITE_BASE']),
  SUFFIX_URL: state.getIn(['config', 'SUFFIX_URL']),
  TENANDSIX_URL: state.getIn(['config', 'TENANDSIX_URL']),
  BROKER_PORTAL_URL: state.getIn(['config', 'BROKER_PORTAL_URL']),
  isSavingCallMeBack: state.getIn(['callMeBack', 'isSaving']),
  menuOpen: state.getIn(['callMeBack', 'menuOpen']),
  currentPage: state.getIn(['router', 'location', 'pathname']),
  productSelection: state.getIn(['productSelection', 'products']),
  rehydratedLastLocation: state.getIn([
    'rehydration',
    'rehydratedLastLocation',
  ]),
  packageUrlSlug: state.getIn(['selectedPackageSlug']),
  rehydrationLink: state.getIn(['rehydration', 'url']),
  state,
});

export const mapDispatchToProps = (dispatch) => ({
  saveCallmeBackDetails: (details) => dispatch(saveCallMeBackDetails(details)),
  openCallmeBackMenu: () => dispatch(openCallmeBackMenu()),
  closeCallmeBackMenu: () => dispatch(closeCallmeBackMenu()),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
