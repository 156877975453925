import * as R from 'ramda';
import IemasSalaryDeduction from './IemasSalaryDeduction';
import InlineDebitOrderForm from './InlineDebitOrderForm';
import PersalSalaryDeduction from './PersalSalaryDeduction';
import { onloadRestart as restartPersal } from '../../actions/persal';
import { onloadRestart as restartSalaryDeduction } from '../../actions/salaryDeduction';
import getBasePackageId, {
  toIndexedPackages,
} from '../../.shared/getBasePackageId';
import { getSelectedPackageIdFromState } from '../../common/getPackageIdFromSlug';
import { isFromBrokerPortal } from '../../common/brokers';
import SalaryDeduction from './SalaryDeduction';
import { isAllowedPaymentMethod, isQaActiveSale,  getBrokerSaleType} from '../../common/saleOptions';

export const getSupportsPersal = (state) => {
  const packageUrlSlug = state.get('selectedPackageSlug');
  if (state.getIn(['packages', packageUrlSlug])) {
    return state
      .getIn(['packages', packageUrlSlug])
      .get('supportsPersalPayment');
  }
  return true;
};

const whenAllowed = (paymentType) => (state) => isAllowedPaymentMethod(state, paymentType);

const isSupportedPackageAndAllowedPaymentType = (paymentType) => (state) => {
  const packageId = getSelectedPackageIdFromState(state);
  const packages = toIndexedPackages(
    state.get('packages').valueSeq().toJS()
  );

  const basePackage = getBasePackageId(packages, packageId);

  const allowedBasePackages = ['sim-family', 'sim-flexi-funeral'];

  return (
    allowedBasePackages.includes(basePackage) &&
    isFromBrokerPortal(state) &&
    isAllowedPaymentMethod(state, paymentType)
  );
};

const config = [
  {
    text: 'Debit order',
    value: 'debit-order',
    isSupported: whenAllowed('debit-order'),
    component: InlineDebitOrderForm,
    onLoadAction: null,
  },
  {
    text: 'Persal salary deduction',
    value: 'persal',
    isSupported: isSupportedPackageAndAllowedPaymentType('persal'),
    component: PersalSalaryDeduction,
    onLoadAction: restartPersal,
  },
  {
    text: 'iMas salary deduction',
    value: 'iemas-salary-deduction',
    isSupported: whenAllowed('iemas-salary-deduction'),
    component: IemasSalaryDeduction,
    onLoadAction: null,
  },
];

const toPaymentOptionEntry = provider => {
  const paymentTypeWithSuffix = `salary-deduction|${provider.id}`;
  return {
    text: provider.name,
    value: paymentTypeWithSuffix,
    isSupported: isSupportedPackageAndAllowedPaymentType('salary-deduction'),
    component: SalaryDeduction,
    onLoadAction: restartSalaryDeduction
  };
};

export const getFinishButtonText = (state) => {
  let finishButtonText = "Finish";
  const brokerSaleType = getBrokerSaleType(state);

  if(!isQaActiveSale(state)) {
    return finishButtonText;
  }

  switch (brokerSaleType) {
    case "sale":
    case "corrections":
      finishButtonText = "Send to QA";
      break;
    case "qa":
      finishButtonText = "Approve";
      break;
    default:
      finishButtonText = "Finish";
      break;
  }

  return finishButtonText;
};

export const checkIfBrokerQASaleCanContinue = (state, isBrokerQACheckFormValid) => {
  let canContinue = true;
  const brokerSaleType = state.getIn(['brokerQa', 'brokerSaleType']);

  switch (brokerSaleType) {
    case "sale":
      canContinue = true;
      break;
    case "corrections":
      canContinue = true;
      break;
    case "qa":
      canContinue = isBrokerQACheckFormValid;
      break;
    default:
      canContinue = true;
      break;
  }

  return canContinue;
};

const toConfigMap = R.pipe(
  R.map((pt) => [pt.value, pt]),
  R.fromPairs
);

export const processSalaryDeductionProviders = (providers) => {
  const processedProviders = R.reduce(
    (conf, provider) => R.append(toPaymentOptionEntry(provider), conf),
    [],
    providers
  );

  return toConfigMap(processedProviders);
};

export const configMap = toConfigMap(config);

export default configMap;
