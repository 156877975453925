/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import HomeQuote from './HomeQuote';
import PageSeo from '../common/PageSeo';
import PageDescription from '../common/PageDescription';
import ReplaceAndSaveBanner from '../replaceAndSave/integration/BannerContainer';

const quoteContainerRef = 'quotecontainer';

export default class Home extends PureComponent {
  render() {
    const Page = () => {

      return (
        <React.Fragment>
          <PageDescription
            description="Get a quote"
            subHeading="Hi! Simply here. We'll get you a price in seconds."
          />

          <ReplaceAndSaveBanner />
          <HomeQuote />
        </React.Fragment>);
    };

    return (
      <div className="home-page">
        <PageSeo
          image="https://storage.googleapis.com/simply-public/cloud_home.jpg"
          description="Awesome insurance this way everyone! Great Value! Instant signup! No Blood tests! Simple Combos: Life, Disability, and Family Funeral Cover."
          title="Simply"
        />
        <div ref={quoteContainerRef}>
          <Page />
        </div>
      </div>
    );
  }
}
