import * as types from './types';
import request from 'axios';
import { API_ROUTES } from '../config/constants';
import {
  iemasMemberNumberFormatValid,
  toNotEnoughDigitsResponse,
  toEnoughDigitsResponse,
} from '../.shared/validators/validatePaymentDetailInput';

const toFailureResponse = (memberNumber, message, continueSale) => {
  const statusMessage = message || 'Member number not found in Iemas CRM.';
  const canContinue = continueSale || false;
  return {
    memberNumber,
    found: false,
    status: statusMessage, //'Member number not found or Iemas CRM down. This may result in salary deduction failures and policy lapse.',
    canContinue, //temp fix for IEMAS CRM being down. Set to true to bypass
  };
};

const toValidationResponse = (memberNumber, iemasMemberData) => {
  let contactMatch = 'and Member contact number match provided contact number.';

  if(!iemasMemberData.contactMatchesProvidedNumber){
    contactMatch = 'and Member contact number does not match provided with number';
  }
  if (!iemasMemberData.fasIncluded) {
    return toFailureResponse(
      memberNumber,
      'Member number not found in Iemas CRM.',
      false
    );
  }
  if (!iemasMemberData.idMatchesProvidedId) {
    return {
      memberNumber,
      found: true,
      status: `Member id or passport number does not match provided id or passport number ${  contactMatch}`,
      canContinue: false,
    };
  };
  if (iemasMemberData.fasIncluded === 'No') {
    return {
      memberNumber,
      found: true,
      status: 'FAS Deductions not allowed for this member',
      canContinue: false,
    };
  }
  if (iemasMemberData.groupCategory === 'Inactive Employer') {
    return {
      memberNumber,
      found: true,
      status:
        'Warning: Employer not active for deductions. This may result in salary deduction failures and policy lapse.',
      canContinue: true,
    };
  }
  return {
    memberNumber,
    found: true,
    status: (contactMatch === 'and Member contact number match provided contact number.') ? '' : `Member number is valid ${  contactMatch}`,
    canContinue: true,
  };
};

const setIemasValidationResponse = (payload) => ({
  type: types.VALIDATE_IEMAS_MEMBER_NUMBER_SUCCESS,
  payload,
});

export const validateIemasMember = (memberNumber) => {
  const type = types.VALIDATE_IEMAS_MEMBER_NUMBER;
  const payload = (dispatch) => {
    if (!iemasMemberNumberFormatValid(memberNumber)) {
      dispatch(
        setIemasValidationResponse(toNotEnoughDigitsResponse(memberNumber))
      );
    } else {
      dispatch(
        setIemasValidationResponse(toEnoughDigitsResponse(memberNumber))
      );
    }
  };
  return {
    type,
    payload,
  };
};

export const validateAndPostIemasMember = (memberNumber) => {
  const type = types.VALIDATE_IEMAS_MEMBER_NUMBER;
  const payload = (dispatch) => {
    if (!iemasMemberNumberFormatValid(memberNumber)) {
      dispatch(
        setIemasValidationResponse(toNotEnoughDigitsResponse(memberNumber))
      );
    } else {
      request
        .get(API_ROUTES.FETCH_IEMAS_MEMBER, {
          params: { memberNumber },
        })
        .then((res) =>
          dispatch(
            setIemasValidationResponse(
              toValidationResponse(memberNumber, res.data)
            )
          )
        )
        .catch(() => {
          dispatch(
            setIemasValidationResponse(
              toFailureResponse(
                memberNumber,
                'Cannot validate member due to IMas CRM not responding. This may result in salary deduction failures and policy lapse.',
                true
              )
            )
          );
        });
    }
  };
  return {
    type,
    payload,
  };
};
