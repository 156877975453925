import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import IPropTypes from 'react-immutable-proptypes';
import { fromJS } from 'immutable';
import ProductCustomization from './ProductCustomization';
import PageDescription from '../common/PageDescription';
import CustomizationSummary from './CustomizationSummary';
import IWantMore from '../common/IWantMore';
import RopSelector from './RopSelector';
import ExtendedFamilyFuneralSelector from './ExtendedFamilyFuneralSelector';
import {
  calculatePricingFromState,
  getSelectedProductPricing,
  getSuggestedPricing,
} from './calculatePricing';

import SummaryActions from './SummaryActions';
import RehydrationContainer from './rehydrationContainer';
import ContactDetailsContainer from '../common/ContactDetailsContainer';
import { getContactDetailsValidations } from '../common/ContactDetails';
import styled from 'styled-components';

import { sendSelectionQuotes } from '../../actions/productSelection';
import generateHealthMessage from './generateHealthMessage';
import {
  SecondaryButton,
  Buttons,
  Eyebrow,
} from '@simply-fin-services/astronomix3';
import PrepareQuoteContainer from '../quote/PrepareQuoteContainer';
import { toPremium } from '../common/numberFormats';
import { isFromBrokerPortal } from '../../common/brokers';
import { CustomisationPageSummaryContainer } from '../replaceAndSave/integration/CustomisationPageSummaryContainer';

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */

/* eslint-enable max-len */

const toCustomizableProduct = (product, selectCoverAmount) => {
  const { title, selected, productId, insuredAmount, premium, tooltip } =
    product.toJS();
  const onSlide = (value) => selectCoverAmount(productId, value);
  return (
    <ProductCustomization
      key={productId}
      productId={productId}
      title={title}
      premium={premium}
      insuredAmount={insuredAmount}
      tooltip={tooltip}
      selected={selected}
      onSlide={onSlide}
    />
  );
};

const toSummary = (
  products,
  pricingConfig,
  pricing,
  hasPackageDiscount,
  packageDiscountDescription,
  winWinDetails
) => {
  const selectedArray = products.toJS();
  const {
    aboveMinPremium,
    cost,
    ropPremium,
    ropReturn,
    discountAmount,
    discountRate,
    winWinDiscountAmount,
    finalCost,
    isNotAffordable,
  } = pricing;

  const { minimumPremium, winWinDiscountRate, withRop } = pricingConfig;

  if (cost > 0) {
    return (
      <CustomizationSummary
        selectedProducts={selectedArray}
        cost={cost}
        minimumPremium={minimumPremium}
        discountRate={discountRate}
        winWinDiscountRate={winWinDiscountRate}
        withRop={withRop}
        winWinDiscountAmount={winWinDiscountAmount}
        ropPremium={ropPremium}
        ropReturn={ropReturn}
        discountAmount={discountAmount}
        finalCost={finalCost}
        isNotAffordable={isNotAffordable}
        aboveMinPremium={aboveMinPremium}
        hasPackageDiscount={hasPackageDiscount}
        packageDiscountDescription={packageDiscountDescription}
        winWinDetails={winWinDetails}
      />
    );
  }
  return null;
};

class CustomizationPage extends PureComponent {
  constructor() {
    super();
    this.selectCoverAmount = this.selectCoverAmount.bind(this);
    this.updateProductSelection = this.updateProductSelection.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.showBrokerPopup = this.showBrokerPopup.bind(this);
    this.notifyClient = this.notifyClient.bind(this);
    this.optOut = this.optOut.bind(this);
  }

  hidePopup(showQuote) {
    this.setState({
      showPopup: false,
    });
    showQuote(true);
  }

  hideBrokerPopup(showQuote) {
    this.setState({
      showBrokerPopup: false,
      hasNotifiedClient: false,
    });
    showQuote(true);
    this.notifyClient();
  }

  showBrokerPopup() {
    this.setState({ showBrokerPopup: true });
  }

  optOut(compoOptOut) {
    this.setState({ hasOptOut: !this.state.hasOptOut });
    compoOptOut(true);
  }

  componentWillMount() {
    const { customizationProducts, answers } = this.props;
    const {
      atleastOneQuestionExistsOnAnswers,
      atleastOneQuestionIsAnsweredAndValid,
    } = getContactDetailsValidations(answers);
    const cellAnswredBeforeMount = atleastOneQuestionExistsOnAnswers
      ? atleastOneQuestionIsAnsweredAndValid
      : false;
    this.setState({
      customizationProducts,
      showPopup: true,
      cellAnswredBeforeMount,
      hasOptOut: false,
      hasUpdatedProductSelection: true,
    });
  }

  componentDidMount() {
    const {
      pricingConfig,
      packageUrlSlug,
      saveRopDetails,
      state: globalState,
    } = this.props;
    const { customizationProducts } = this.state;

    const customizationProductsWithSelectedPricing = customizationProducts.map(
      (p) => p.merge(getSelectedProductPricing(p))
    );

    let productSelection = globalState.setIn(
      ['productSelection', 'products'],
      customizationProductsWithSelectedPricing
    );

    let extendedFamily = fromJS({ premium: 0 });
    if (pricingConfig.withExtendedFamily) {
      extendedFamily = globalState
        .getIn(['productSelection', 'products'])
        .find((p) => p.get('isExtendedFamily'));

      if (extendedFamily) {
        productSelection = productSelection.setIn(
          ['productSelection', 'products', extendedFamily.get('productId')],
          extendedFamily
        );
      }

      extendedFamily = extendedFamily || fromJS({ premium: 0 });
    }

    const pricing = calculatePricingFromState(productSelection, packageUrlSlug);
    const { hasRopOption } = pricingConfig;

    const { ropPremium, ropPrecentage, ropReturn, ropTotalPremium, finalCost } =
      pricing;

    const ropDetails = {
      ropPremium,
      ropPrecentage,
      ropReturn,
      ropTotalPremium,
      finalCost,
      hasRopOption,
    };
    saveRopDetails(ropDetails);
  }

  updateProductSelection() {
    const { customizationProducts } = this.state;
    const customizationProductsWithSelectedPricing = customizationProducts.map(
      (p) => p.merge(getSelectedProductPricing(p))
    );

    const products = customizationProductsWithSelectedPricing.valueSeq();
    this.props.updateProductSelection(products);
    this.setState({ hasUpdatedProductSelection: true });
  }

  selectCoverAmount(productId, index) {
    const { customizationProducts } = this.state;
    const isSelected = parseInt(index, 10) !== 0;
    this.setState(
      {
        customizationProducts: customizationProducts
          .setIn([productId, 'selectedIndex'], index)
          .setIn([productId, 'selected'], isSelected),
      },
      this.updateProductSelection
    );
  }

  notifyClient() {
    const { customizationProducts } = this.state;
    const { pricingConfig, packageUrlSlug, packageId, state, dispatch } =
      this.props;
    const customizationProductsWithSelectedPricing = customizationProducts.map(
      (p) => p.merge(getSelectedProductPricing(p))
    );

    let updatedState = state.setIn(
      ['productSelection', 'products'],
      customizationProductsWithSelectedPricing
    );
    let extendedFamily = fromJS({ premium: 0 });
    if (pricingConfig.withExtendedFamily) {
      extendedFamily = state
        .getIn(['productSelection', 'products'])
        .find((p) => p.get('isExtendedFamily'));

      if (extendedFamily) {
        updatedState = updatedState.setIn(
          ['productSelection', 'products', extendedFamily.get('productId')],
          extendedFamily
        );
      }
      extendedFamily = extendedFamily || fromJS({ premium: 0 });
    }
    const pricing = calculatePricingFromState(updatedState, packageUrlSlug);
    const cellphoneNumber = this.props.state.getIn([
      'answers',
      'owner-contact-number',
    ]);
    const email = this.props.state.getIn(['answers', 'email']);
    const canContact = cellphoneNumber || email;
    if (canContact) {
      dispatch(
        sendSelectionQuotes(
          packageId,
          updatedState.getIn(['productSelection', 'products']).valueSeq(),
          pricing,
          R.T(),
          R.T()
        )
      );
      this.setState({
        hasNotifiedClient: true,
        hasUpdatedProductSelection: false,
      });
    } else {
      this.setState({ showPopup: true, hasUpdatedProductSelection: false });
    }
  }

  render() {
    const { customizationProducts } = this.state;
    const {
      next,
      showQuote,
      isSaving,
      toggleRop,
      toggleExtendedFamily,
      pricingConfig,
      winWinDetails,
      sortProducts,
      hasViewedQuote,
      compoOptOut,
      summaryContent,
      packageUrlSlug,
      RequestCustomizationCellphone,
      hasPackageDiscount,
      packageDiscountDescription,
      healthMessageConfig,
      showHealthMessage,
      state: globalState,
      currentExtendedFamilyInsured,
      setCurrentInsuredValue,
      setCurrentInsuredValues,
      insureds,
      addInsured,
      fetchPricingForCurrentInsured,
      addExtendedFamilyWithSelectedFamilyMembers,
      refreshCurrentInsured,
      insuredRefreshed,
      maximumFuneralCoverAmount,
      downloadQuote,
      setPrepareQuotePopupFlag,
      packageId,
      allowedEffRelationships,
      packageAllowsEffWithoutFuneral,
      benefits,
    } = this.props;
    const customizationProductsWithSelectedPricing = customizationProducts.map(
      (p) => p.merge(getSelectedProductPricing(p))
    );

    let productSelectionState = globalState.setIn(
      ['productSelection', 'products'],
      customizationProductsWithSelectedPricing
    );

    let extendedFamily = fromJS({ premium: 0 });
    if (pricingConfig.withExtendedFamily) {
      extendedFamily = globalState
        .getIn(['productSelection', 'products'])
        .find((p) => p.get('isExtendedFamily'));

      if (extendedFamily) {
        productSelectionState = productSelectionState.setIn(
          ['productSelection', 'products', extendedFamily.get('productId')],
          extendedFamily
        );
      }

      extendedFamily = extendedFamily || fromJS({ premium: 0 });
    }

    const pricing = calculatePricingFromState(
      productSelectionState,
      packageUrlSlug
    );

    const selectedPackage = globalState.getIn(['packages', packageUrlSlug]);
    const canDownloadQuotePdf =
      selectedPackage.getIn(['brokerCanDownloadQuotePdf']) &&
      isFromBrokerPortal(globalState);

    const {
      hasRopOption,
      withRop,
      hasExtendedFamilyOption,
      withExtendedFamily,
      extendedFamilyProductId,
      winWinDiscountRate,
    } = pricingConfig;
    const effProduct = globalState.getIn(['products', extendedFamilyProductId]);

    const effProductTooltip = hasExtendedFamilyOption
      ? selectedPackage.get('effProductTooltipOverride') ||
        effProduct.get('tooltip')
      : '';
    const effTooltipLink = hasExtendedFamilyOption
      ? effProduct.get('link')
      : '';

    const effTooltipDetails = hasExtendedFamilyOption
      ? effProduct.get('detail')
      : '';
    const effTooltipExtraDetail = hasExtendedFamilyOption
      ? effProduct.get('extraDetail')
      : '';

    const suggestedPricing = getSuggestedPricing(
      globalState,
      customizationProductsWithSelectedPricing
    );
    const suggestedFinalCost = suggestedPricing.finalCost;
    const {
      ropPremium,
      ropPrecentage,
      ropReturn,
      ropTotalPremium,
      discountRate,
      aboveMinPremium,
      minimumPremium,
    } = pricing;

    const products = customizationProductsWithSelectedPricing.valueSeq();
    const accept = () => next(products.toJS());
    const canGoToNextPage = pricing.aboveMinPremium;
    const discountText = hasPackageDiscount
      ? '(10% online discount when you take all 3 benefits)'
      : ' ';
    const { shouldRequestCellPhone } = RequestCustomizationCellphone;
    const { cellAnswredBeforeMount, hasOptOut } = this.state;
    const showPopup =
      !hasViewedQuote &&
      shouldRequestCellPhone &&
      this.state.showPopup &&
      !cellAnswredBeforeMount;
    const hide = () => this.hidePopup(showQuote);
    const hideBroker = () => this.hideBrokerPopup(showQuote);
    const selectOptOut = () => this.optOut(compoOptOut);
    const cashbackDiscount = discountRate + winWinDiscountRate;
    const showNotifyQuote = this.props.isBrokerSale;
    const cellphoneNumber = this.props.state.getIn([
      'answers',
      'owner-contact-number',
    ]);
    const canContact = !R.isNil(cellphoneNumber) && !R.isEmpty(cellphoneNumber);
    const { showBrokerPopup } = this.state;

    const {
      hasLifeBenefit,
      hasDisabilityBenefit,
      hasFuneralbenefit,
      hasEffBenefit,
    } = benefits;

    const description = this.props.isReplaceAndSaveEnabled ? (
      <div>
        Here’s your custom quote, <br />
        which you can adjust to meet your needs.
      </div>
    ) : (
      <div>
        Here’s your custom quote, <br />
        which you can adjust to meet your needs.
      </div>
    );

    return (
      <div>
        <React.Fragment>
          <PageDescription
            description={description}
            subHeading={discountText}
          />
        </React.Fragment>
        {!aboveMinPremium && (
          <Eyebrow colour="red" className="text-bold text-danger space-below">
            Your premium is below the minimum of {toPremium(minimumPremium)}.
          </Eyebrow>
        )}

        <CustomisationPageSummaryContainer
          quoteMonthlyPremium={pricing.finalCost}
        />

        {aboveMinPremium && (
          <h5 className="space-below">
            For{' '}
            <span className="text-bold">{toPremium(pricing.finalCost)}</span>{' '}
            you get:
          </h5>
        )}

        {showPopup && (
          <ContactDetailsContainer
            packageSlug={packageUrlSlug}
            hide={hide}
            optOut={selectOptOut}
            hasOptOut={hasOptOut}
          />
        )}
        {showBrokerPopup && (
          <ContactDetailsContainer
            packageSlug={packageUrlSlug}
            hide={hideBroker}
            hasOptOut={false}
            title="Please capture the following details from the client"
            contactDetailsPopupVisisble
            isCompulsory
          />
        )}
        <div>
          <PricingContainer>
            {sortProducts(products).map((p) =>
              toCustomizableProduct(p, this.selectCoverAmount)
            )}
            {hasExtendedFamilyOption && this.props.extendedFamilySelectable && (
              <ExtendedFamilyFuneralSelector
                currentFuneralCoverAmount={this.props.currentFuneralCoverAmount}
                editInsured={this.props.editInsured}
                deleteInsured={this.props.deleteInsured}
                updateInsured={this.props.updateInsured}
                toggleExtendedFamily={toggleExtendedFamily}
                withExtendedFamily={withExtendedFamily}
                extendedFamilyProductId={extendedFamilyProductId}
                setCurrentInsuredValue={setCurrentInsuredValue}
                setCurrentInsuredValues={setCurrentInsuredValues}
                currentExtendedFamilyInsured={currentExtendedFamilyInsured}
                insureds={insureds}
                addInsured={addInsured}
                fetchPricingForCurrentInsured={fetchPricingForCurrentInsured}
                addExtendedFamilyWithSelectedFamilyMembers={
                  addExtendedFamilyWithSelectedFamilyMembers
                }
                refreshCurrentInsured={refreshCurrentInsured}
                insuredRefreshed={insuredRefreshed}
                maximumFuneralCoverAmount={maximumFuneralCoverAmount}
                packageId={packageId}
                effProduct={effProduct}
                effProductTooltip={effProductTooltip}
                effTooltipLink={effTooltipLink}
                effTooltipDetails={effTooltipDetails}
                effTooltipExtraDetail={effTooltipExtraDetail}
                allowedRelationships={allowedEffRelationships}
                packageAllowsEffWithoutFuneral={packageAllowsEffWithoutFuneral}
              />
            )}
            {hasRopOption && (
              <RopSelector
                withRop={withRop}
                amount={ropPremium}
                rate={ropPrecentage}
                discountRate={cashbackDiscount}
                ropReturn={ropReturn}
                ropTotalPremium={ropTotalPremium}
                toggle={toggleRop}
              />
            )}
            {toSummary(
              products,
              pricingConfig,
              pricing,
              hasPackageDiscount,
              packageDiscountDescription,
              winWinDetails,
              extendedFamily.get('premium')
            )}
            {showHealthMessage &&
              generateHealthMessage(
                healthMessageConfig.toJS(),
                suggestedFinalCost
              )}
            <SummaryActions
              next={accept}
              nextDisabled={!canGoToNextPage}
              isSaving={isSaving}
              summaryContent={summaryContent}
            />
            {(hasEffBenefit || hasDisabilityBenefit) &&
              !hasLifeBenefit &&
              !hasFuneralbenefit && (
                <p className="text-center small">
                  Please note you are automatically the beneficiary for the
                  benefits selected above.
                </p>
              )}
          </PricingContainer>
          <PrepareQuoteContainer
            downloadQuote={downloadQuote(products.toJS())}
          />
        </div>
        <div className="rehydration-container">
          {showNotifyQuote && (
            <div className="text-center">
              <p> Send quote to client </p>
              <Buttons>
                <SecondaryButton
                  onClick={
                    canContact ? this.notifyClient : this.showBrokerPopup
                  }
                  disabled={
                    this.state.hasNotifiedClient &&
                    !this.state.hasUpdatedProductSelection
                  }
                >
                  {this.state.hasNotifiedClient &&
                  !this.state.hasUpdatedProductSelection
                    ? 'SMS Sent'
                    : 'Send SMS'}
                </SecondaryButton>
                {canDownloadQuotePdf && (
                  <SecondaryButton
                    onClick={() => setPrepareQuotePopupFlag(true)}
                  >
                    Download
                  </SecondaryButton>
                )}
              </Buttons>
            </div>
          )}
          <br />
          <RehydrationContainer />
        </div>
        <IWantMore />
      </div>
    );
  }
}

CustomizationPage.protoTypes = {
  customizationProducts: IPropTypes.map.isRequired,
  next: PropTypes.func.isRequired,
  showQuote: PropTypes.func.isRequired,
  toggleRop: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  pricingConfig: PropTypes.shape({
    discountRate: PropTypes.number.isRequired,
    winWinDiscountRate: PropTypes.number.isRequired,
    withRop: PropTypes.bool.isRequired,
    hasRopOption: PropTypes.bool.isRequired,
    minimumPremium: PropTypes.number.isRequired,
    productRopPriceMap: PropTypes.object.isRequired,
  }).isRequired,
  sortProducts: PropTypes.func.isRequired,
  updateProductSelection: PropTypes.func.isRequired,
  showHealthMessage: PropTypes.bool,
  healthMessageConfig: PropTypes.object,
};

export default CustomizationPage;
