import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  ShareToWhatsapp,
  ShareToTwitter,
  ShareToMessenger,
  ShareToFacebook,
  ShareToEmail,
} from '../common/SocialSharing';
import { Buttons } from '@simply-fin-services/astronomix3';

/* eslint-disable max-len */
const toWhatsappText = referralUrl => `Check out this awesome new life, disability and funeral insurance from Simply! I think it’s great. I’m sure you will too!
Use my Simply Referral link to buy Life, Disability or Family Funeral Cover and you’ll get a 5% discount on your premiums thanks to me.  To get a quote or buy now click here: ${referralUrl}.`;

const toTwitterText = referralUrl =>
  `I like what Simply does and I’m sure you will too! Use my Simply Referral link to buy Life, Disability or Family Funeral Cover and you’ll get a 5% discount thanks to me.  To get a quote or buy now click here: ${referralUrl}. @simply_co_za`;

const emailSubject = 'I have your back for life…';
export const toEmailText = referralUrl => `Hi Friend

Check out this awesome new life, disability and funeral insurance from Simply! I think it’s great. I’m sure you will too!

Simply makes it easy for you to get the cover you need at a great price. You can cover yourself, your domestic worker or your employees.  And you can do it all on your phone in less than 5 minutes. Cover starts immediately and there are no blood tests or complicated medical questions.

Use my Simply Referral link to buy Life, Disability or Family Funeral Cover and you’ll get a 5% discount on your premiums thanks to me.  To get a quote or buy now click here: ${referralUrl}.

If you like what you see, you can also sign up to the Simply Referral Programme and refer your friends. It’s a great programme - your friends get a discount and you get cash for you or a charity of your choice.

Chat soon!`;

export default class ShareButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { copied: false };
  }

  render() {
    const { referralUrl, redirectUri } = this.props;
    return (
      <Buttons>
        <ShareToWhatsapp text={toWhatsappText(referralUrl)} />
        <ShareToFacebook shareText url={referralUrl} />
        <ShareToTwitter
          text={toTwitterText(referralUrl)}
          url=""
          hashtags="Simplyreferralprogramme"
        />
        <ShareToMessenger
          shareText
          url={referralUrl}
          redirectUri={redirectUri}
        />
        <ShareToEmail subject={emailSubject} body={toEmailText(referralUrl)} />
      </Buttons>
    );
  }
}

ShareButtons.propTypes = {
  referralUrl: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
};
