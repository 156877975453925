/* eslint-disable max-len*/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from '../../config/constants';
import icon from '../../images/robot-female-i.svg';
import {
  SecondaryButton,
  PrimaryButton,
  Buttons,
} from '@simply-fin-services/astronomix3';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

class ReferralBannerThankYouPageBase extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <div className="row">
        <div className="col-xs-12">
          <h5 className="bold">Share with your friends</h5>
          <p>
            Please make sure your friends hear about our great products and our
            simple sign up process! It’s super easy - just join our referral
            programme and share. If your friend buys a policy, they get a
            discount and you get cash for yourself or a charity of your choice.
          </p>
          {children}
        </div>
      </div>
    );
  }
}
class ReferralBannerBase extends PureComponent {
  render() {
    const { children, isShort, style } = this.props;
    const imageHeight = !isShort ? '170px' : '115px';
    return (
      <div className="row info-row light banner" style={style}>
        <div className="col-sm-3 referral-image hidden-xs hidden-sm">
          <img
            src={icon}
            alt="refer and get paid"
            style={{ height: imageHeight }}
          />
        </div>
        <div className="col-xs-12 col-md-9 banner-content">
          <div className="row">
            <div className="col-xs-12 text-center info-header light">
              <h1>Simply Referral Programme</h1>
            </div>
            <p className="summary-info">
              Join our referral programme for free.
              <br />
              Your friends get a discount and you get cash for yourself or a
              charity of your choice.
            </p>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

ReferralBannerBase.propTypes = {
  isShort: PropTypes.bool,
};

export class HomeBanner extends PureComponent {
  render() {
    const { redirect } = this.props;
    return (
      <ReferralBannerBase isShort>
        <div className="col-xs-12 line line-4 p-15">
          <SecondaryButton onClick={() => redirect(ROUTES.REFERRAL_SIGNUP)}>
            SIGN UP NOW
          </SecondaryButton>
        </div>
      </ReferralBannerBase>
    );
  }
}
const mapHomeBannerDispatchToProps = (dispatch) => ({
  redirect: (route) => dispatch(push(route)),
});

export const ReferralBannerHome = connect(
  null,
  mapHomeBannerDispatchToProps
)(HomeBanner);

export class ThankYouPageBanner extends PureComponent {
  render() {
    const bannerStyle = { marginBottom: '0' };
    const { redirect } = this.props;
    return (
      <ReferralBannerThankYouPageBase isShort style={bannerStyle}>
        <div className="space-below">
          <Buttons>
            <PrimaryButton onClick={() => redirect(ROUTES.REFERRAL_SIGNUP)}>
              Sign up
            </PrimaryButton>
          </Buttons>
          <br />
        </div>
      </ReferralBannerThankYouPageBase>
    );
  }
}

const mapThankYouPageBannerDispatchToProps = (dispatch) => ({
  redirect: (route) => dispatch(push(route)),
});

export const ReferralBannerThankYou = connect(
  null,
  mapThankYouPageBannerDispatchToProps
)(ThankYouPageBanner);

export class ReferralBannerYouAreWinning extends PureComponent {
  render() {
    const { name, isShort, style } = this.props;
    const imageHeight = !isShort ? '170px' : '115px';
    return (
      <div className="row info-row dark banner" style={style}>
        <div className="col-sm-3 referral-image hidden-xs hidden-sm">
          <img
            src={icon}
            alt="refer and get paid"
            style={{ height: imageHeight }}
          />
        </div>
        <div className="col-xs-12 col-md-9 banner-content">
          <div className="row">
            <div className="col-xs-12 line line-name">
              {name} you are
              <br />
              SIMPLY
              <br />
              WIN-WINNING!
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default class ReferralBanner extends PureComponent {
  render() {
    return <ReferralBannerBase />;
  }
}
